import React from 'react'
import PageLayout from '../PageLayout'
import ClasspartsTable from './ClasspartsTable'

// const useStyles = makeStyles(theme => ({
//     content: {
//        //
//     },
// }))


export default function AppClasspartsPage() {
    // const classes = useStyles()

    // console.log('AppClasspartsPage render')

    return (
        <PageLayout>
            <ClasspartsTable />
        </PageLayout>
    )
}

