import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LinearProgress from '@material-ui/core/LinearProgress'
import Fade from '@material-ui/core/Fade'
import DoneIcon from '@material-ui/icons/Done'
import { green } from '@material-ui/core/colors'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import mioBackground from '../../resource/mio-background.jpg'
import validator from 'validator'
import ReactRouterLink from '../../component/ReactRouterLink'
import authActions from '../../action/auth'
import { routes } from '../../helper/routes'


const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      height: "100vh",
      minHeight: "100%"
    },
    body: {
      background: `url(${mioBackground}) no-repeat center center fixed`,
      backgroundSize: "cover",
      backgroundColor: "#333"
    },
  },
  paper: {
    marginTop: '14vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    minWidth: '400px'
  },
  logo: {
    marginBottom: theme.spacing(2),
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    border: '1px solid #ced4da',
  },
  submit: {
    margin: theme.spacing(2, 0, 2)
  },
  placeholder: {
    marginTop: theme.spacing(1),
    height: 10
  },
  errorMessage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  links: {
    marginTop: theme.spacing(1)
  }
}))


function LinkSentCard({ email }) {
  return (
    <CardContent>
      <Box m={2}>
        <Typography variant="h6" gutterBottom align="center">Link inviato</Typography>
        <Box display="flex" alignItems="center" mt={2}>
          <DoneIcon style={{ fontSize: 50, color: green[500], marginRight: 10 }} />
          <Typography variant="body2" gutterBottom >
            Il link per reimpostare la password è stato inviato a <strong>{email}</strong>.<br />Controlla la posta in arrivo.
          </Typography>
        </Box>
      </Box>
    </CardContent>
  )
}


function ForgotPasswordCard({ classes, state, handlers }) {

  return (
    <CardContent>
      <Box m={2}>
        <Typography variant="h6" gutterBottom align="center">Reimposta la password</Typography>
        <Typography variant="body2" gutterBottom align="center">
          Inserisci il tuo indirizzo email di seguito e ti invieremo un link per reimpostare la password.
              </Typography>
      </Box>
      <form className={classes.form} noValidate onSubmit={handlers.handleSubmit}>
        <TextField
          disabled={state.isSubmitted}
          error={state.isEmailError}
          helperText={state.isEmailError ? "Indirizzo email non valido" : null}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Il tuo indirizzo email"
          name="email"
          autoComplete="email"
          autoFocus
          spellCheck='false'
          value={state.email}
          onChange={event => handlers.setEmail(event.target.value)}
        />
        <div className={classes.placeholder}>
          <Fade
            in={state.isSubmitted}
            style={{
              transitionDelay: state.isSubmitted ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <LinearProgress />
          </Fade>
        </div>
        <Button
          disabled={state.isSubmitted}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Invia mail di reimpostazione password
              </Button>
        <Grid container className={classes.links} align="center">
          <Grid item xs>
            <Link to={routes.login} component={ReactRouterLink} variant="body2">
              Ritorna alla pagina di login
                  </Link>
          </Grid>
        </Grid>
      </form>
    </CardContent>
  )
}


function ForgotPassword({ dispatch }) {
  const classes = useStyles()

  const [email, setEmail] = React.useState('')
  const [isEmailError, setIsEmailError] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [isRequestSuccess, setIsRequestSuccess] = React.useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    const isInvalidEmail = !validator.isEmail(email)
    if (isInvalidEmail) {
      setIsEmailError(true)
    }
    else {
      setIsSubmitted(true)
      setIsEmailError(false)
      try {
        const request = { email }
        await dispatch(authActions.sendResetPasswordLink(request))
        setIsRequestSuccess(true)
      }
      catch (error) {
        setIsSubmitted(false)
      }
    }
  }

  const cardContent = isRequestSuccess ?
    <LinkSentCard email={email} /> :
    <ForgotPasswordCard
      classes={classes}
      state={{ isSubmitted, isEmailError }}
      handlers={{ handleSubmit, setEmail }} />


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Card className={classes.card}>
          {cardContent}
        </Card>
      </Box>
    </Container>
  )
}


export default connect()(ForgotPassword)