import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import DynamicTable from '../../component/DynamicTable'
import TableEditTextField from '../../component/TableEditTextField'
import permissionActions from '../../action/permission'
import userActions from '../../action/user'
import mainLayoutActions from '../../action/mainLayout'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'



function PermissionsTable({ dispatch, totalCount }) {

  const [isErrorSlug, setIsErrorSlug] = React.useState(false)
  const [isErrorName, setIsErrorName] = React.useState(false)

  const validatePermission = data => {
    const _isErrorSlug = _.isNil(data.slug) || data.slug === ''
    const _isErrorName = _.isNil(data.name) || data.name === ''
    setIsErrorSlug(_isErrorSlug)
    setIsErrorName(_isErrorName)
    if (_isErrorSlug || _isErrorName) {
      dispatch(mainLayoutActions.warningSnackbar('I campi Slug e Nome sono richiesti'))
      throw new Error()
    }
  }

  const updatePermissions = async () => {
    await dispatch(permissionActions.readList())
    await dispatch(userActions.readProfile())
  }

  const onRowAdd = async newData => {
    validatePermission(newData)
    await dispatch(permissionActions.createOne(newData))
    await updatePermissions()
  }

  const onRowUpdate = async (newData, oldData) => {
    validatePermission(newData)
    await dispatch(permissionActions.updateOne(newData))
    await updatePermissions()
  }

  const onRowDelete = async oldData => {
    await dispatch(permissionActions.deleteOne(oldData))
    await updatePermissions()
  }

  const fetchPaginatedData = query => dispatch(permissionActions.readPaginated(getQueryParams(query)))

  return (
    <DynamicTable
      columns={[
        {
          title: "Id", field: "id", editable: 'onAdd',
          editComponent: props => <TableEditTextField props={props} />
        },
        {
          title: "Slug", field: "slug",
          editComponent: props => <TableEditTextField options={{ error: isErrorSlug }} props={props} />
        },
        {
          title: "Nome", field: "name",
          editComponent: props => <TableEditTextField options={{ error: isErrorName }} props={props} />
        },
        { title: "Data creazione", field: "created_at", editable: 'never' },
        { title: "Data aggiornamento", field: "updated_at", editable: 'never', defaultSort: 'desc' },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
      editable={{ onRowAdd, onRowUpdate, onRowDelete }}
    />
  )
}

const mapStateToProps = store => {
  const { permission } = store
  return {
    totalCount: permission.totalCount
  }
}

export default connect(mapStateToProps)(PermissionsTable)