

export const patternConstants = {
  UPDATE_PATTERN_REQUEST: 'UPDATE_PATTERN_REQUEST',
  UPDATE_PATTERN_SUCCESS: 'UPDATE_PATTERN_SUCCESS',
  UPDATE_PATTERN_FAILURE: 'UPDATE_PATTERN_FAILURE',
  DELETE_PATTERN_REQUEST: 'DELETE_PATTERN_REQUEST',
  DELETE_PATTERN_SUCCESS: 'DELETE_PATTERN_SUCCESS',
  DELETE_PATTERN_FAILURE: 'DELETE_PATTERN_FAILURE',
  CREATE_PATTERN_REQUEST: 'CREATE_PATTERN_REQUEST',
  CREATE_PATTERN_SUCCESS: 'CREATE_PATTERN_SUCCESS',
  CREATE_PATTERN_FAILURE: 'CREATE_PATTERN_FAILURE',
  READ_PATTERNS_REQUEST: 'READ_PATTERNS_REQUEST',
  READ_PATTERNS_SUCCESS: 'READ_PATTERNS_SUCCESS',
  READ_PATTERNS_FAILURE: 'READ_PATTERNS_FAILURE',
}
