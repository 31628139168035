import React from 'react'
import { makeStyles, Button, Box, Divider, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { routes, replaceAppId } from '../../helper/routes'


const useStyles = makeStyles((theme) => ({
  side: {
    paddingLeft: theme.spacing(4),
    width: 240,
    '& > *': {
      marginBottom: theme.spacing(2),
    }
  }
}))

function LabelValue(props) {
  const { label, value } = props
  return (
    <Box>
      <Typography style={{ fontSize: '0.8rem', color: '#aaa' }}>
        {label}
      </Typography>
      <Typography style={{ fontSize: '0.8rem' }}>
        {value}
      </Typography>
    </Box>
  )
}


export default function AppInfoSidebar(props) {
  const classes = useStyles()
  const history = useHistory()
  const param = useParams()
  const { app } = props

  const handleClick = () => {
    history.push(replaceAppId(routes.appEdit, param.appId))
  }

  // console.log('AppInfoSidebar render', app)

  return (
    <Box className={classes.side}>
      <Button variant='contained' color='primary' size='small' disableElevation onClick={handleClick}>
        Modifica App
      </Button>
      <Divider />
      <Typography>
        App Info
      </Typography>

      <LabelValue label='App ID' value={app.route_id} />
      <LabelValue label='App Status' value={app.is_disabled ? 'Disabilitata' : 'Abilitata'} />

    </Box>
  )
}

