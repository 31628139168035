import axiosCommon from '../helper/axiosCommon'
import qs from 'qs'


async function updateOne(product) {
  const req = axiosCommon.getAxiosParamsAuth(`/products/${product.id}`, 'put', product)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/products/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(product) {
  const req = axiosCommon.getAxiosParamsAuth('/products', 'post', product)
  return await axiosCommon.axiosRequest(req)
}

async function readPaginated(query) {
  const req = axiosCommon.getAxiosParamsAuth('/products?' + qs.stringify(query), 'get')
  return await axiosCommon.axiosRequest(req)
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
}


