

export const materialConstants = {
  UPDATE_MATERIAL_REQUEST: 'UPDATE_MATERIAL_REQUEST',
  UPDATE_MATERIAL_SUCCESS: 'UPDATE_MATERIAL_SUCCESS',
  UPDATE_MATERIAL_FAILURE: 'UPDATE_MATERIAL_FAILURE',
  DELETE_MATERIAL_REQUEST: 'DELETE_MATERIAL_REQUEST',
  DELETE_MATERIAL_SUCCESS: 'DELETE_MATERIAL_SUCCESS',
  DELETE_MATERIAL_FAILURE: 'DELETE_MATERIAL_FAILURE',
  CREATE_MATERIAL_REQUEST: 'CREATE_MATERIAL_REQUEST',
  CREATE_MATERIAL_SUCCESS: 'CREATE_MATERIAL_SUCCESS',
  CREATE_MATERIAL_FAILURE: 'CREATE_MATERIAL_FAILURE',
  READ_MATERIALS_REQUEST: 'READ_MATERIALS_REQUEST',
  READ_MATERIALS_SUCCESS: 'READ_MATERIALS_SUCCESS',
  READ_MATERIALS_FAILURE: 'READ_MATERIALS_FAILURE',
}
