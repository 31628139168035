import React from 'react'
import PageLayout from '../PageLayout'
import Statistics from './Statistics'

// const useStyles = makeStyles(theme => ({
//     content: {
//        //
//     },
// }))


export default function AppStatisticsPage() {
    // const classes = useStyles()

    // console.log('AppStatisticsPage render')

    return (
        <PageLayout>
            <Statistics />
        </PageLayout>
    )
}

