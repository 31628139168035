import React from 'react'
import { connect } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'
import clsx from 'clsx'
// import _ from 'lodash'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import mioLogo from '../../resource/mio-logo.png'
import Box from '@material-ui/core/Box'
// import DrawerMenuItems from './DrawerMenuItems'
import mainLayoutActions from '../../action/mainLayout'
import { routes } from '../../helper/routes'
// import ReactRouterLink from '../../component/ReactRouterLink'
import { List, /*ListItem, ListItemText, ListItemIcon,*/ Link } from '@material-ui/core'
// import AppsIcon from '@material-ui/icons/Apps'
// import AccountCircleIcon from '@material-ui/icons/AccountCircle'

const drawerWidth = 230

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    overflowX: "hidden",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8.7),
    },
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "flex-end",
    paddingLeft: '8px',
    paddingBottom: '8px',
    height: '100%',
    ...theme.mixins.toolbar,
  },
  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxHeight: "34px",
    maxWidth: "60%"
  },
  itemIconPrimary: {
    marginLeft: 6,
  },
  itemPrimaryText: {
    fontSize: 15,
    color: '#ffffff'
  }
}))



function DrawerMenu2(props /*{ dispatch, isDrawerOpen, pathname, allUserPermissionSlugs }*/) {
  const classes = useStyles()
  // const location = useLocation()
  const { dispatch, isDrawerOpen, children } = props

  const handleDrawerOpen = () => {
    dispatch(mainLayoutActions.setDrawerOpen())
  }

  const handleDrawerClose = () => {
    dispatch(mainLayoutActions.setDrawerClose())
  }

  // console.log('DrawerMenu2 render')

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose) }}
      open={isDrawerOpen}>

      <Link to={routes.home} component={ReactRouterLink}>
        <Box className={classes.logoContainer}>
          <img src={mioLogo} alt="main-logo" className={classes.logo} />
        </Box>
      </Link>

      <Divider />
      <Box mt={2}>
        <List component="nav" >
          {children}
          {/* <ListItem button selected={location.pathname === routes.home} component={ReactRouterLink} to={routes.home} >
            <ListItemIcon className={classes.itemIconPrimary}>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary='Applicazioni' classes={{ primary: classes.itemPrimaryText }} />
          </ListItem>

          <ListItem button selected={location.pathname === routes.users} component={ReactRouterLink} to={routes.users} >
            <ListItemIcon className={classes.itemIconPrimary}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary='Utenti' classes={{ primary: classes.itemPrimaryText }} />
          </ListItem> */}


          {/* <ListItem button selected={isSelected} component={ReactRouterLink} to={toRoute} >
            <ListItemText primary={title} />
          </ListItem> */}

          {/* <DrawerMenuItem
            rootItem={{
              title: 'Applicazioni',
              icon: <AppsIcon />,
              isSelected: isEqualPath(routes.applications),
              toRoute: routes.applications,
              isVisible: hasPermission(['read-all', 'read-applications'])
            }}
          /> */}

        </List>

        {/* <DrawerMenuItems pathname={pathname} permissionSlugs={allUserPermissionSlugs} /> */}
      </Box>
      <Box className={classes.toolbarIcon}>
        <Tooltip
          enterDelay={1000}
          placement="right"
          title={isDrawerOpen ? "Riduci Menu" : "Espandi Menu"}>
          <IconButton onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen} >
            {isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Tooltip>
      </Box>
    </Drawer>
  )
}


const mapStateToProps = store => {
  const { mainLayout } = store
  return {
    isDrawerOpen: mainLayout.isDrawerOpen
  }
}

export default connect(mapStateToProps)(DrawerMenu2)
