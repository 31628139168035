import { patternConstants } from '../constant/pattern'


export default function pattern(state = {}, action) {
  switch (action.type) {

    case patternConstants.READ_PATTERNS_SUCCESS:
      return { ...state, totalCount: action.resp.totalCount }

    // case patternConstants.READ_PATTERNS_SUCCESS:
    //   return { ...state, readAll: action.resp, isReady: true }

    // case patternConstants.READ_PATTERNS_FAILURE:
    //   return { ...state, isReady: true }

    default:
      return state
  }
}