import { mainLayoutConstants } from '../constant/mainLayout'
import { userConstants } from '../constant/user'
import _ from 'lodash'


export default function mainLayout(state = {}, action) {
  switch (action.type) {

    case mainLayoutConstants.SET_NOTIFICATION_BADGE:
      return { ...state, notificationBadgeContent: action.value }

    case mainLayoutConstants.SET_IS_UPDATE_AVAILABLE:
      return { ...state, isUpdateAvailable: action.value.isAvailable, versionTxt: action.value.version }


    case userConstants.READ_PROFILE_SUCCESS:
      return { ...state, readProfile: { ...action.resp, permissionMap: _.keyBy(action.resp.permissions, 'slug') }, isProfileReady: true }

    case userConstants.READ_PROFILE_FAILURE:
      return { ...state, isProfileReady: true }


    case mainLayoutConstants.DRAWER_OPEN:
      return { ...state, isDrawerOpen: true }

    case mainLayoutConstants.DRAWER_CLOSE:
      return { ...state, isDrawerOpen: false }


    case mainLayoutConstants.ENQUEUE_SNACKBAR: {
      return { ...state, notifications: [...state.notifications, { key: action.key, ...action.notification }] }
    }

    case mainLayoutConstants.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification => (
          (action.dismissAll || notification.key === action.key)
            ? { ...notification, dismissed: true }
            : { ...notification }
        )),
      }

    case mainLayoutConstants.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key,
        ),
      }

    default:
      return state
  }
}
