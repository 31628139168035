import axiosCommon from '../helper/axiosCommon'
import qs from 'qs'


async function updateOne(patternmix) {
  const req = axiosCommon.getAxiosParamsAuth(`/patternmixes/${patternmix.id}`, 'put', patternmix)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/patternmixes/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(patternmix) {
  const req = axiosCommon.getAxiosParamsAuth('/patternmixes', 'post', patternmix)
  return await axiosCommon.axiosRequest(req)
}

async function readPaginated(query) {
  const req = axiosCommon.getAxiosParamsAuth('/patternmixes?' + qs.stringify(query), 'get')
  return await axiosCommon.axiosRequest(req)
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
}


