import React from "react"
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import mainLayoutActions from '../../action/mainLayout'




function SnackbarCloseButton({ dispatch, snackbarKey }) {

  const handleClose = () => {
    dispatch(mainLayoutActions.closeSnackbar(snackbarKey))
  }

  return (
    <IconButton key="close" aria-label="close" onClick={handleClose}>
      <CloseIcon style={{ fontSize: 16 }} />
    </IconButton>
  )
}

const ConnectedSnackbarCloseButton = connect()(SnackbarCloseButton)



export function getSnackbarOptions(variant) {
  return {
    key: new Date().getTime() + Math.random(),
    variant,
    action: key => <ConnectedSnackbarCloseButton snackbarKey={key} />
  }
}