import { colorConstants } from '../constant/color'
import colorService from '../service/color'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: colorConstants.UPDATE_COLOR_REQUEST})
  const success = resp => ({type: colorConstants.UPDATE_COLOR_SUCCESS, resp})
  const failure = () => ({type: colorConstants.UPDATE_COLOR_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await colorService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento colore effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento colore fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: colorConstants.DELETE_COLOR_REQUEST})
  const success = resp => ({type: colorConstants.DELETE_COLOR_SUCCESS, resp})
  const failure = () => ({type: colorConstants.DELETE_COLOR_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await colorService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione colore effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione colore fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: colorConstants.CREATE_COLOR_REQUEST})
  const success = resp => ({type: colorConstants.CREATE_COLOR_SUCCESS, resp})
  const failure = () => ({type: colorConstants.CREATE_COLOR_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await colorService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione colore effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione colore fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  // const request = () => ({type: colorConstants.READ_COLORS_REQUEST})
  const success = resp => ({type: colorConstants.READ_COLORS_SUCCESS, resp})
  const failure = () => ({type: colorConstants.READ_COLORS_FAILURE})
  return async dispatch => {
    // dispatch(request())
    try {
      const resp = await colorService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura colori fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
