import { mainLayoutConstants } from '../constant/mainLayout'
import {getSnackbarOptions} from '../component/layout/SnackbarCloseButton'


function setNotificationBadge(value) {
  return { type: mainLayoutConstants.SET_NOTIFICATION_BADGE, value }
}

function setIsUpdateAvailable(value) {
  return { type: mainLayoutConstants.SET_IS_UPDATE_AVAILABLE, value }
}


function defaultSnackbar(message) {
  return enqueueSnackbar({ message, options: getSnackbarOptions('default') })
}

function successSnackbar(message) {
  return enqueueSnackbar({ message, options: getSnackbarOptions('success') })
}

function errorSnackbar(message) {
  return enqueueSnackbar({ message, options: getSnackbarOptions('error') })
}

function warningSnackbar(message) {
  return enqueueSnackbar({ message, options: getSnackbarOptions('warning') })
}

function infoSnackbar(message) {
  return enqueueSnackbar({ message, options: getSnackbarOptions('info') })
}

function enqueueSnackbar(notification) {
  const key = notification.options && notification.options.key
  return { type: mainLayoutConstants.ENQUEUE_SNACKBAR, notification: { ...notification, key: key || new Date().getTime() + Math.random() } }
}

function closeSnackbar(key) {
  return { type: mainLayoutConstants.CLOSE_SNACKBAR, dismissAll: !key,  key }
}

function removeSnackbar(key) {
  return { type: mainLayoutConstants.REMOVE_SNACKBAR, key }
}


function setDrawerOpen() {
  return { type: mainLayoutConstants.DRAWER_OPEN }
}
function setDrawerClose() {
  return { type: mainLayoutConstants.DRAWER_CLOSE }
}

export default {
  setIsUpdateAvailable,
  setNotificationBadge,
  defaultSnackbar,
  successSnackbar,
  errorSnackbar,
  warningSnackbar,
  infoSnackbar,
  closeSnackbar,
  removeSnackbar,
  setDrawerOpen,
  setDrawerClose
}