

export const compositionConstants = {
  UPDATE_COMPOSITION_REQUEST: 'UPDATE_COMPOSITION_REQUEST',
  UPDATE_COMPOSITION_SUCCESS: 'UPDATE_COMPOSITION_SUCCESS',
  UPDATE_COMPOSITION_FAILURE: 'UPDATE_COMPOSITION_FAILURE',
  DELETE_COMPOSITION_REQUEST: 'DELETE_COMPOSITION_REQUEST',
  DELETE_COMPOSITION_SUCCESS: 'DELETE_COMPOSITION_SUCCESS',
  DELETE_COMPOSITION_FAILURE: 'DELETE_COMPOSITION_FAILURE',
  CREATE_COMPOSITION_REQUEST: 'CREATE_COMPOSITION_REQUEST',
  CREATE_COMPOSITION_SUCCESS: 'CREATE_COMPOSITION_SUCCESS',
  CREATE_COMPOSITION_FAILURE: 'CREATE_COMPOSITION_FAILURE',
  READ_COMPOSITIONS_REQUEST: 'READ_COMPOSITIONS_REQUEST',
  READ_COMPOSITIONS_SUCCESS: 'READ_COMPOSITIONS_SUCCESS',
  READ_COMPOSITIONS_FAILURE: 'READ_COMPOSITIONS_FAILURE',
}
