

export const authConstants = {
  RESETPASSWORD_REQUEST: 'RESETPASSWORD_REQUEST',
  RESETPASSWORD_SUCCESS: 'RESETPASSWORD_SUCCESS',
  RESETPASSWORD_FAILURE: 'RESETPASSWORD_FAILURE',
  SENDRESETLINK_REQUEST: 'SENDRESETLINK_REQUEST',
  SENDRESETLINK_SUCCESS: 'SENDRESETLINK_SUCCESS',
  SENDRESETLINK_FAILURE: 'SENDRESETLINK_FAILURE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
}
