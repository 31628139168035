import { productConstants } from '../constant/product'
import productService from '../service/product'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: productConstants.UPDATE_PRODUCT_REQUEST})
  const success = resp => ({type: productConstants.UPDATE_PRODUCT_SUCCESS, resp})
  const failure = () => ({type: productConstants.UPDATE_PRODUCT_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await productService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento prodotto effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento prodotto fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: productConstants.DELETE_PRODUCT_REQUEST})
  const success = resp => ({type: productConstants.DELETE_PRODUCT_SUCCESS, resp})
  const failure = () => ({type: productConstants.DELETE_PRODUCT_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await productService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione prodotto effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione prodotto fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: productConstants.CREATE_PRODUCT_REQUEST})
  const success = resp => ({type: productConstants.CREATE_PRODUCT_SUCCESS, resp})
  const failure = () => ({type: productConstants.CREATE_PRODUCT_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await productService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione prodotto effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione prodotto fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  // const request = () => ({type: productConstants.READ_PRODUCTS_REQUEST})
  const success = resp => ({type: productConstants.READ_PRODUCTS_SUCCESS, resp})
  const failure = () => ({type: productConstants.READ_PRODUCTS_FAILURE})
  return async dispatch => {
    // dispatch(request())
    try {
      const resp = await productService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura prodotti fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
