import React from 'react'
import PageLayout from '../PageLayout'
import PatternmixesTable from './PatternmixesTable'

// const useStyles = makeStyles(theme => ({
//     content: {
//        //
//     },
// }))


export default function AppPatternmixesPage() {
    // const classes = useStyles()

    // console.log('AppPatternmixesPage render')

    return (
        <PageLayout>
            <PatternmixesTable />
        </PageLayout>
    )
}

