

export const statisticConstants = {
  UPDATE_STATISTIC_REQUEST: 'UPDATE_STATISTIC_REQUEST',
  UPDATE_STATISTIC_SUCCESS: 'UPDATE_STATISTIC_SUCCESS',
  UPDATE_STATISTIC_FAILURE: 'UPDATE_STATISTIC_FAILURE',
  DELETE_STATISTIC_REQUEST: 'DELETE_STATISTIC_REQUEST',
  DELETE_STATISTIC_SUCCESS: 'DELETE_STATISTIC_SUCCESS',
  DELETE_STATISTIC_FAILURE: 'DELETE_STATISTIC_FAILURE',
  CREATE_STATISTIC_REQUEST: 'CREATE_STATISTIC_REQUEST',
  CREATE_STATISTIC_SUCCESS: 'CREATE_STATISTIC_SUCCESS',
  CREATE_STATISTIC_FAILURE: 'CREATE_STATISTIC_FAILURE',
  READ_STATISTICS_REQUEST: 'READ_STATISTICS_REQUEST',
  READ_STATISTICS_SUCCESS: 'READ_STATISTICS_SUCCESS',
  READ_STATISTICS_FAILURE: 'READ_STATISTICS_FAILURE',
}
