

export const configuredPartConstants = {
  UPDATE_CONFIGURED_PART_REQUEST: 'UPDATE_CONFIGURED_PART_REQUEST',
  UPDATE_CONFIGURED_PART_SUCCESS: 'UPDATE_CONFIGURED_PART_SUCCESS',
  UPDATE_CONFIGURED_PART_FAILURE: 'UPDATE_CONFIGURED_PART_FAILURE',
  DELETE_CONFIGURED_PART_REQUEST: 'DELETE_CONFIGURED_PART_REQUEST',
  DELETE_CONFIGURED_PART_SUCCESS: 'DELETE_CONFIGURED_PART_SUCCESS',
  DELETE_CONFIGURED_PART_FAILURE: 'DELETE_CONFIGURED_PART_FAILURE',
  CREATE_CONFIGURED_PART_REQUEST: 'CREATE_CONFIGURED_PART_REQUEST',
  CREATE_CONFIGURED_PART_SUCCESS: 'CREATE_CONFIGURED_PART_SUCCESS',
  CREATE_CONFIGURED_PART_FAILURE: 'CREATE_CONFIGURED_PART_FAILURE',
  READ_CONFIGURED_PARTS_REQUEST: 'READ_CONFIGURED_PARTS_REQUEST',
  READ_CONFIGURED_PARTS_SUCCESS: 'READ_CONFIGURED_PARTS_SUCCESS',
  READ_CONFIGURED_PARTS_FAILURE: 'READ_CONFIGURED_PARTS_FAILURE',
}
