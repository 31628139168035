import React from "react"
import { Route, Redirect } from 'react-router-dom'
import authHelper from '../../helper/auth'
import { routes } from '../../helper/routes'

export function PrivateRoute({ component: Component, ...other }) {
  // console.log('PrivateRoute render')
  return (
    <Route
      {...other}
      render={props => {
        // console.log('other.location', other.location.pathname)
        const isValidToken = authHelper.isTokenExistentAndValid()
        const isLogin = other.location.pathname === routes.login
        if (isLogin) {
          return isValidToken ? (
            <Redirect to={{ pathname: routes.home, state: { from: props.location } }} />
          ) : (
            <Component {...props} />
          )
        } else {
          return isValidToken ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: routes.login, state: { from: props.location } }} />
          )
        }
      }}
    />
  )
}
