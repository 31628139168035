import { geometryConstants } from '../constant/geometry'
import geometryService from '../service/geometry'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: geometryConstants.UPDATE_GEOMETRY_REQUEST})
  const success = resp => ({type: geometryConstants.UPDATE_GEOMETRY_SUCCESS, resp})
  const failure = () => ({type: geometryConstants.UPDATE_GEOMETRY_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await geometryService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento geometria effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento geometria fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: geometryConstants.DELETE_GEOMETRY_REQUEST})
  const success = resp => ({type: geometryConstants.DELETE_GEOMETRY_SUCCESS, resp})
  const failure = () => ({type: geometryConstants.DELETE_GEOMETRY_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await geometryService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione geometria effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione geometria fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: geometryConstants.CREATE_GEOMETRY_REQUEST})
  const success = resp => ({type: geometryConstants.CREATE_GEOMETRY_SUCCESS, resp})
  const failure = () => ({type: geometryConstants.CREATE_GEOMETRY_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await geometryService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione geometria effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione geometria fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  // const request = () => ({type: geometryConstants.READ_GEOMETRIES_REQUEST})
  const success = resp => ({type: geometryConstants.READ_GEOMETRIES_SUCCESS, resp})
  const failure = () => ({type: geometryConstants.READ_GEOMETRIES_FAILURE})
  return async dispatch => {
    // dispatch(request())
    try {
      const resp = await geometryService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura geometrie fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
