import React from 'react'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import DynamicTable from '../../component/DynamicTable'
import colorActions from '../../action/color'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'
import { useParams } from 'react-router-dom'



function ColorField({ colorLabel }) {
  const isNilLabel = _.isNil(colorLabel)
  return (
    <Grid container spacing={0}>
      <Grid item style={{ width: '70px' }}>{!isNilLabel && <Box>{colorLabel}</Box>}</Grid>
      <Grid item>{!isNilLabel && <Box style={{ width: '50px', height: '20px', backgroundColor: '#' + colorLabel, border: '1px solid #333' }} />}</Grid>
    </Grid>
  )
}

function ColorsTable({ dispatch, totalCount }) {
  const params = useParams();

  const fetchPaginatedData = query => dispatch(colorActions.readPaginated(getQueryParams({ ...query, appId: params.appId })))

  return (
    <DynamicTable
      columns={[
        { title: "Id", field: "id" },
        { title: "Uid", field: "color_id" },
        { title: "Titolo", field: "title" },
        { title: "Codice hex", field: "code", render: rowData => <ColorField colorLabel={rowData.code} /> },
        // { title: "App", field: "app_title" },
        { title: "Abilitato", field: "is_disabled", render: rowData => !rowData.is_disabled ? 'Si' : 'No' },
        { title: "Extra", field: "extra" },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
    />
  )
}

const mapStateToProps = store => {
  const { color } = store
  return {
    totalCount: color.totalCount
  }
}


export default connect(mapStateToProps)(ColorsTable)