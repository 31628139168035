import { roleConstants } from '../constant/role'


export default function role(state = {}, action) {
  switch (action.type) {

    case roleConstants.READ_ROLES_LIST_SUCCESS:
      return { ...state, list: action.resp }

    case roleConstants.READ_ROLES_SUCCESS:
      return { ...state, totalCount: action.resp.totalCount }

    // case roleConstants.READ_ROLES_SUCCESS:
    //   return { ...state, readAll: action.resp, isReady: true }
    
    // case roleConstants.READ_ROLES_FAILURE:
    //   return { ...state, isReady: true }
    
    default:
      return state
  }
}