

export const patternmixConstants = {
  UPDATE_PATTERNMIX_REQUEST: 'UPDATE_PATTERNMIX_REQUEST',
  UPDATE_PATTERNMIX_SUCCESS: 'UPDATE_PATTERNMIX_SUCCESS',
  UPDATE_PATTERNMIX_FAILURE: 'UPDATE_PATTERNMIX_FAILURE',
  DELETE_PATTERNMIX_REQUEST: 'DELETE_PATTERNMIX_REQUEST',
  DELETE_PATTERNMIX_SUCCESS: 'DELETE_PATTERNMIX_SUCCESS',
  DELETE_PATTERNMIX_FAILURE: 'DELETE_PATTERNMIX_FAILURE',
  CREATE_PATTERNMIX_REQUEST: 'CREATE_PATTERNMIX_REQUEST',
  CREATE_PATTERNMIX_SUCCESS: 'CREATE_PATTERNMIX_SUCCESS',
  CREATE_PATTERNMIX_FAILURE: 'CREATE_PATTERNMIX_FAILURE',
  READ_PATTERNMIXES_REQUEST: 'READ_PATTERNMIXES_REQUEST',
  READ_PATTERNMIXES_SUCCESS: 'READ_PATTERNMIXES_SUCCESS',
  READ_PATTERNMIXES_FAILURE: 'READ_PATTERNMIXES_FAILURE',
}
