import { compositionConstants } from '../constant/composition'
import compositionService from '../service/composition'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: compositionConstants.UPDATE_COMPOSITION_REQUEST})
  const success = resp => ({type: compositionConstants.UPDATE_COMPOSITION_SUCCESS, resp})
  const failure = () => ({type: compositionConstants.UPDATE_COMPOSITION_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await compositionService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento composizione effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento composizione fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: compositionConstants.DELETE_COMPOSITION_REQUEST})
  const success = resp => ({type: compositionConstants.DELETE_COMPOSITION_SUCCESS, resp})
  const failure = () => ({type: compositionConstants.DELETE_COMPOSITION_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await compositionService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione composizione effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione composizione fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: compositionConstants.CREATE_COMPOSITION_REQUEST})
  const success = resp => ({type: compositionConstants.CREATE_COMPOSITION_SUCCESS, resp})
  const failure = () => ({type: compositionConstants.CREATE_COMPOSITION_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await compositionService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione composizione effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione composizione fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  //const request = () => ({type: compositionConstants.READ_COMPOSITIONS_REQUEST})
  const success = resp => ({type: compositionConstants.READ_COMPOSITIONS_SUCCESS, resp})
  const failure = () => ({type: compositionConstants.READ_COMPOSITIONS_FAILURE})
  return async dispatch => {
    //dispatch(request())
    try {
      const resp = await compositionService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura composizioni fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
