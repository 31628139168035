import axiosCommon from '../helper/axiosCommon'
import qs from 'qs'


async function updateOne(classpart) {
  const req = axiosCommon.getAxiosParamsAuth(`/classparts/${classpart.id}`, 'put', classpart)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/classparts/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(classpart) {
  const req = axiosCommon.getAxiosParamsAuth('/classparts', 'post', classpart)
  return await axiosCommon.axiosRequest(req)
}

async function readPaginated(query) {
  const req = axiosCommon.getAxiosParamsAuth('/classparts?' + qs.stringify(query), 'get')
  return await axiosCommon.axiosRequest(req)
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
}


