import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import { Button, CardContent, Typography, Box, Divider } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'


const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  title: {
    // fontSize: '1rem'
    fontWeight: 'bold'
  },
  total: {
    fontSize: '1.8rem',
    fontWeight: 'bold'
  },
  icon: {
    fontSize: 12
  },
  bulletText: {
    marginLeft: theme.spacing(0.5),
    fontSize: '0.8rem'
  },
  bullets: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    '& > *': {
      width: 50,
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    '& > *': {
      marginLeft: theme.spacing(1),
    }
  }
}));


function BulletInfo(props) {
  const classes = useStyles()
  const { count, color } = props
  return (
    <Box display='flex' alignItems="center" style={{ color }}>
      <FiberManualRecordIcon className={classes.icon} />
      <Typography className={classes.bulletText}>
        {count}
      </Typography>
    </Box>
  )
}


export default function AppEntityCard(props) {
  const classes = useStyles()
  const { title, data = {} } = props

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} gutterBottom>
            {title}
          </Typography>
          <Typography className={classes.total} gutterBottom>
            {data.total}
          </Typography>
          <Box className={classes.bullets}>
            <BulletInfo count={data.enabled} color='#43d86d' />
            <BulletInfo count={data.disabled} color='#ff932e' />
          </Box>
          <Divider />
          <Box className={classes.buttons}>
            <Button variant="outlined" color='primary' size='small'>Gestisci</Button>
            <Button variant="contained" color='primary' size='small' disableElevation>Crea</Button>
          </Box>
        </CardContent>

        {/* <CardHeader
          title={title}
          classes={{
            title: classes.title
          }}
        />
        <CardActions>
          <Button size="small">Manage</Button>
          <Button size="small">Add new</Button>
        </CardActions> */}
      </Card>


    </div>

  )
}
