import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))


export default function ChipArray({ array = [], prop = null }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {array.map((value, i) =>
        <Chip key={i} label={prop !== null ? value[prop] : value} className={classes.chip} size="small" />
      )}
    </div>
  )
}