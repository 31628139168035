import React from 'react'
import { connect } from 'react-redux'
import DynamicTable from '../../component/DynamicTable'
import patternActions from '../../action/pattern'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'
import { useParams } from 'react-router-dom'


function PatternsTable({ dispatch, totalCount }) {
  const params = useParams();

  const fetchPaginatedData = query => dispatch(patternActions.readPaginated(getQueryParams({ ...query, appId: params.appId })))

  return (
    <DynamicTable
      columns={[
        { title: "Id", field: "id" },
        { title: "Uid", field: "pattern_id" },
        { title: "Titolo", field: "title" },
        { title: "Materiale", field: "material_title" },
        { title: "Colore", field: "color_title" },
        { title: "Geometria", field: "geometry_title" },
        // { title: "App", field: "app_title" },
        { title: "Abilitato", field: "is_disabled", render: rowData => !rowData.is_disabled ? 'Si' : 'No' },
        { title: "Extra", field: "extra" },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
    />
  )
}

const mapStateToProps = store => {
  const { pattern } = store
  return {
    totalCount: pattern.totalCount
  }
}


export default connect(mapStateToProps)(PatternsTable)