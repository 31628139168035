import axiosCommon from '../helper/axiosCommon'
import qs from 'qs'


async function updateOne(material) {
  const req = axiosCommon.getAxiosParamsAuth(`/materials/${material.id}`, 'put', material)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/materials/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(material) {
  const req = axiosCommon.getAxiosParamsAuth('/materials', 'post', material)
  return await axiosCommon.axiosRequest(req)
}

async function readPaginated(query) {
  const req = axiosCommon.getAxiosParamsAuth('/materials?' + qs.stringify(query), 'get')
  return await axiosCommon.axiosRequest(req)
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
}


