import { patternConstants } from '../constant/pattern'
import patternService from '../service/pattern'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: patternConstants.UPDATE_PATTERN_REQUEST})
  const success = resp => ({type: patternConstants.UPDATE_PATTERN_SUCCESS, resp})
  const failure = () => ({type: patternConstants.UPDATE_PATTERN_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await patternService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento pattern effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento pattern fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: patternConstants.DELETE_PATTERN_REQUEST})
  const success = resp => ({type: patternConstants.DELETE_PATTERN_SUCCESS, resp})
  const failure = () => ({type: patternConstants.DELETE_PATTERN_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await patternService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione pattern effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione pattern fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: patternConstants.CREATE_PATTERN_REQUEST})
  const success = resp => ({type: patternConstants.CREATE_PATTERN_SUCCESS, resp})
  const failure = () => ({type: patternConstants.CREATE_PATTERN_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await patternService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione pattern effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione pattern fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  // const request = () => ({type: patternConstants.READ_PATTERNS_REQUEST})
  const success = resp => ({type: patternConstants.READ_PATTERNS_SUCCESS, resp})
  const failure = () => ({type: patternConstants.READ_PATTERNS_FAILURE})
  return async dispatch => {
    // dispatch(request())
    try {
      const resp = await patternService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura patterns fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
