import React from 'react'
import PageLayout from '../PageLayout'
import ColorsTable from './ColorsTable'

// const useStyles = makeStyles(theme => ({
//     content: {
//        //
//     },
// }))


export default function AppColorsPage() {
    // const classes = useStyles()

    // console.log('AppColorsPage render')

    return (
        <PageLayout>
            <ColorsTable />
        </PageLayout>
    )
}

