import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, Link } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText, Collapse, List } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import _ from 'lodash'


const useStyles = makeStyles(theme => ({
    itemIconPrimary: {
        marginLeft: 6,
    },
    itemPrimaryText: {
        fontSize: 15,
        color: '#ffffff'
    }
}))


export default function DrawerMenu2Item(props) {
    const classes = useStyles()
    const location = useLocation()
    const { title, route, icon, children } = props

    // console.log('DrawerMenu2Item render', children)

    const isOpen = location.pathname === route || (children && children.some(c => !_.isNil(c) && location.pathname === c.props.route))

    return (
        <div>
            <ListItem button selected={location.pathname === route} component={Link} to={route} >
                <ListItemIcon className={classes.itemIconPrimary}>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={title} classes={{ primary: classes.itemPrimaryText }} />
                {children && children.some(c => !_.isNil(c)) ? (isOpen ? <ExpandLess color='action' /> : <ExpandMore color='action' />) : null}
            </ListItem>
            {children && children.some(c => !_.isNil(c)) &&
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children}
                    </List>
                </Collapse>
            }

        </div>
    )
}

