import axiosCommon from '../helper/axiosCommon'
import qs from 'qs'


async function updateOne(instancepart) {
  const req = axiosCommon.getAxiosParamsAuth(`/instanceparts/${instancepart.id}`, 'put', instancepart)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/instanceparts/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(instancepart) {
  const req = axiosCommon.getAxiosParamsAuth('/instanceparts', 'post', instancepart)
  return await axiosCommon.axiosRequest(req)
}

async function readPaginated(query) {
  const req = axiosCommon.getAxiosParamsAuth('/instanceparts?' + qs.stringify(query), 'get')
  return await axiosCommon.axiosRequest(req)
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
}


