import { materialConstants } from '../constant/material'
import materialService from '../service/material'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: materialConstants.UPDATE_MATERIAL_REQUEST})
  const success = resp => ({type: materialConstants.UPDATE_MATERIAL_SUCCESS, resp})
  const failure = () => ({type: materialConstants.UPDATE_MATERIAL_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await materialService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento materiale effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento materiale fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: materialConstants.DELETE_MATERIAL_REQUEST})
  const success = resp => ({type: materialConstants.DELETE_MATERIAL_SUCCESS, resp})
  const failure = () => ({type: materialConstants.DELETE_MATERIAL_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await materialService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione materiale effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione materiale fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: materialConstants.CREATE_MATERIAL_REQUEST})
  const success = resp => ({type: materialConstants.CREATE_MATERIAL_SUCCESS, resp})
  const failure = () => ({type: materialConstants.CREATE_MATERIAL_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await materialService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione materiale effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione materiale fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  // const request = () => ({type: materialConstants.READ_MATERIALS_REQUEST})
  const success = resp => ({type: materialConstants.READ_MATERIALS_SUCCESS, resp})
  const failure = () => ({type: materialConstants.READ_MATERIALS_FAILURE})
  return async dispatch => {
    // dispatch(request())
    try {
      const resp = await materialService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura materiali fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
