import { configuredProductConstants } from '../constant/configuredProduct'
import configuredProductService from '../service/configuredProduct'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: configuredProductConstants.UPDATE_CONFIGURED_PRODUCT_REQUEST})
  const success = resp => ({type: configuredProductConstants.UPDATE_CONFIGURED_PRODUCT_SUCCESS, resp})
  const failure = () => ({type: configuredProductConstants.UPDATE_CONFIGURED_PRODUCT_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await configuredProductService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento prodotto-configurato effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento prodotto-configurato fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: configuredProductConstants.DELETE_CONFIGURED_PRODUCT_REQUEST})
  const success = resp => ({type: configuredProductConstants.DELETE_CONFIGURED_PRODUCT_SUCCESS, resp})
  const failure = () => ({type: configuredProductConstants.DELETE_CONFIGURED_PRODUCT_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await configuredProductService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione prodotto-configurato effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione prodotto-configurato fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: configuredProductConstants.CREATE_CONFIGURED_PRODUCT_REQUEST})
  const success = resp => ({type: configuredProductConstants.CREATE_CONFIGURED_PRODUCT_SUCCESS, resp})
  const failure = () => ({type: configuredProductConstants.CREATE_CONFIGURED_PRODUCT_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await configuredProductService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione prodotto-configurato effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione prodotto-configurato fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  //const request = () => ({type: configuredProductConstants.READ_CONFIGURED_PRODUCTS_REQUEST})
  const success = resp => ({type: configuredProductConstants.READ_CONFIGURED_PRODUCTS_SUCCESS, resp})
  const failure = () => ({type: configuredProductConstants.READ_CONFIGURED_PRODUCTS_FAILURE})
  return async dispatch => {
    //dispatch(request())
    try {
      const resp = await configuredProductService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura prodotti-configurati fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
