import axiosCommon from '../helper/axiosCommon'
import qs from 'qs'


async function updateOne(composition) {
  const req = axiosCommon.getAxiosParamsAuth(`/compositions/${composition.id}`, 'put', composition)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/compositions/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(composition) {
  const req = axiosCommon.getAxiosParamsAuth('/compositions', 'post', composition)
  return await axiosCommon.axiosRequest(req)
}

async function readPaginated(query) {
  const req = axiosCommon.getAxiosParamsAuth('/compositions?' + qs.stringify(query), 'get')
  return await axiosCommon.axiosRequest(req)
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
}


