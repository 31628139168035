import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LinearProgress from '@material-ui/core/LinearProgress'
import Fade from '@material-ui/core/Fade'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import { green } from '@material-ui/core/colors'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CardContent from '@material-ui/core/CardContent'
import mioBackground from '../../resource/mio-background.jpg'
import ReactRouterLink from '../../component/ReactRouterLink'
import { red } from '@material-ui/core/colors'
import qs from 'qs'
import validator from 'validator'
import authActions from '../../action/auth'
import { routes } from '../../helper/routes'


const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      height: "100vh",
      minHeight: "100%"
    },
    body: {
      background: `url(${mioBackground}) no-repeat center center fixed`,
      backgroundSize: "cover",
      backgroundColor: "#333"
    },
  },
  paper: {
    marginTop: '14vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    minWidth: '400px'
  },
  logo: {
    marginBottom: theme.spacing(2),
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    border: '1px solid #ced4da',
  },
  submit: {
    margin: theme.spacing(2, 0, 2)
  },
  placeholder: {
    marginTop: theme.spacing(1),
    height: 10
  },
  errorMessage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  links: {
    marginTop: theme.spacing(1)
  }
}))


function InvalidLinkCard() {
  return (
    <CardContent>
      <Box m={2}>
        <Typography variant="h6" gutterBottom align="center">Errore</Typography>
        <Box display="flex" alignItems="center" mt={2}>
          <ErrorOutlineIcon style={{ fontSize: 50, color: red[500], marginRight: 10 }} />
          <Typography variant="body2" gutterBottom >
            Il link di reimpostazione della password non è valido.
          </Typography>
        </Box>
      </Box>
    </CardContent>
  )
}


function ResettedPasswordCard({ classes, email }) {
  return (
    <CardContent>
      <Box m={2}>
        <Typography variant="h6" gutterBottom align="center">Password reimpostata</Typography>
        <Box display="flex" alignItems="center" mt={2}>
          <DoneIcon style={{ fontSize: 50, color: green[500], marginRight: 10 }} />
          <Typography variant="body2" gutterBottom >
            La password dell'account <strong>{email}</strong> è stata reimpostata correttamente.
          </Typography>
        </Box>
      </Box>
      <Grid container className={classes.links} align="center">
        <Grid item xs>
          <Link to={routes.login} component={ReactRouterLink} variant="body2">
            Ritorna alla pagina di login
          </Link>
        </Grid>
      </Grid>
    </CardContent>
  )
}


function ResetPasswordCard({ classes, state, handlers }) {

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <CardContent>
      <Box m={2}>
        <Typography variant="h6" gutterBottom align="center">Reimposta la password</Typography>
        <Typography variant="body2" gutterBottom align="center">
          Inserisci la nuova password.
        </Typography>
      </Box>
      <form className={classes.form} noValidate onSubmit={handlers.handleSubmit}>
        <TextField
          disabled={state.isSubmitted}
          error={state.isPasswordError}
          helperText={state.isPasswordError ? "Password non valida" : null}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          value={state.password}
          onChange={event => handlers.setPassword(event.target.value)}
          spellCheck='false'
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  disabled={state.isSubmitted}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={event => event.preventDefault()}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
          }}
        />
        <div className={classes.placeholder}>
          <Fade
            in={state.isSubmitted}
            style={{
              transitionDelay: state.isSubmitted ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <LinearProgress />
          </Fade>
        </div>
        <Button
          disabled={state.isSubmitted}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Reimposta la password
              </Button>
      </form>
    </CardContent>
  )
}




function ResetPassword({ dispatch, location }) {
  const classes = useStyles()

  const qsParam = qs.parse(location.search, { ignoreQueryPrefix: true })
  const isValidQsParam = qsParam.hasOwnProperty('token') && qsParam.token.length === 64 &&
    qsParam.hasOwnProperty('email') && validator.isEmail(qsParam.email)
  const token = isValidQsParam ? qsParam.token : ''
  const email = isValidQsParam ? qsParam.email : ''

  const [password, setPassword] = React.useState('')
  const [isPasswordError, setIsPasswordError] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [isRequestSuccess, setIsRequestSuccess] = React.useState(false)


  const handleSubmit = async event => {
    event.preventDefault()
    const isInvalidPassword = password.trim().length < 4 || /\s/.test(password)
    if (isInvalidPassword) {
      setIsPasswordError(true)
    }
    else {
      setIsSubmitted(true)
      setIsPasswordError(false)
      try {
        const request = { token, email, password }
        await dispatch(authActions.resetPassword(request))
        setIsRequestSuccess(true)
      }
      catch (error) {
        setIsSubmitted(false)
      }
    }
  }

  let cardContent = null
  if (isValidQsParam) {
    if (isRequestSuccess) {
      cardContent = <ResettedPasswordCard
        classes={classes}
        email={email} />
    }
    else {
      cardContent = <ResetPasswordCard
        classes={classes}
        state={{ isSubmitted, isPasswordError, password }}
        handlers={{ handleSubmit, setPassword }} />
    }
  }
  else {
    cardContent = <InvalidLinkCard />
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Card className={classes.card}>
          {cardContent}
        </Card>
      </Box>
    </Container>
  )
}


export default connect()(ResetPassword)