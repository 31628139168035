import { configuredPartConstants } from '../constant/configuredPart'


export default function configuredPart(state = {}, action) {
  switch (action.type) {

    case configuredPartConstants.READ_CONFIGURED_PARTS_SUCCESS:
      return { ...state, totalCount: action.resp.totalCount }

    // case configuredPartConstants.READ_CONFIGURED_PARTS_SUCCESS:
    //   return { ...state, readAll: action.resp, isReady: true }

    // case configuredPartConstants.READ_CONFIGURED_PARTS_FAILURE:
    //   return { ...state, isReady: true }

    default:
      return state
  }
}