import { mapConstants } from '../constant/map'
import mapService from '../service/map'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: mapConstants.UPDATE_MAP_REQUEST})
  const success = resp => ({type: mapConstants.UPDATE_MAP_SUCCESS, resp})
  const failure = () => ({type: mapConstants.UPDATE_MAP_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await mapService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento mappa effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento mappa fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: mapConstants.DELETE_MAP_REQUEST})
  const success = resp => ({type: mapConstants.DELETE_MAP_SUCCESS, resp})
  const failure = () => ({type: mapConstants.DELETE_MAP_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await mapService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione mappa effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione mappa fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: mapConstants.CREATE_MAP_REQUEST})
  const success = resp => ({type: mapConstants.CREATE_MAP_SUCCESS, resp})
  const failure = () => ({type: mapConstants.CREATE_MAP_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await mapService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione mappa effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione mappa fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  // const request = () => ({type: mapConstants.READ_MAPS_REQUEST})
  const success = resp => ({type: mapConstants.READ_MAPS_SUCCESS, resp})
  const failure = () => ({type: mapConstants.READ_MAPS_FAILURE})
  return async dispatch => {
    // dispatch(request())
    try {
      const resp = await mapService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura mappe fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
