import React from 'react'
import PageLayout from '../PageLayout'
import UsersTable from './UsersTable'
import { useParams } from 'react-router-dom'

// const useStyles = makeStyles(theme => ({
//     content: {
//        //
//     },
// }))


export default function AppUsersPage() {
    // const classes = useStyles()
    const params = useParams()

    // console.log('AppUsersPage render')

    return (
        <PageLayout>
            <UsersTable appId={params.appId} />
        </PageLayout>
    )
}

