import React from 'react'
import PageLayout from './PageLayout'
import UsersTable from './users/UsersTable'
// import userService from '../service/user'

// const useStyles = makeStyles(theme => ({
//     content: {
//        //
//     },
// }))


export default function UsersPage(props) {
    // const classes = useStyles()
    // const { dispatch, user } = props
    // const [resp, setResp] = React.useState(null)

    // console.log('UsersPage render')

    // React.useEffect(() => {
    //   const fetchData = async () => {
    //     const resp = await userService.readPaginated()
    //     setResp(resp)
    //   }
    //   fetchData()
    // }, [])

    return (
        <PageLayout mainDrawer={true}>
            {/* {JSON.stringify(resp, null, 2)} */}
            <UsersTable />
        </PageLayout>
    )
}

// const mapStateToProps = store => {
//     const { mainLayout } = store
//     return {
//         user: mainLayout.readProfile
//     }
// }

// export default connect(mapStateToProps)(UsersPage)
