import { instancepartConstants } from '../constant/instancepart'
import instancepartService from '../service/instancepart'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: instancepartConstants.UPDATE_INSTANCEPART_REQUEST})
  const success = resp => ({type: instancepartConstants.UPDATE_INSTANCEPART_SUCCESS, resp})
  const failure = () => ({type: instancepartConstants.UPDATE_INSTANCEPART_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await instancepartService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento istanza-parte effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento istanza-parte fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: instancepartConstants.DELETE_INSTANCEPART_REQUEST})
  const success = resp => ({type: instancepartConstants.DELETE_INSTANCEPART_SUCCESS, resp})
  const failure = () => ({type: instancepartConstants.DELETE_INSTANCEPART_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await instancepartService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione istanza-parte effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione istanza-parte fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: instancepartConstants.CREATE_INSTANCEPART_REQUEST})
  const success = resp => ({type: instancepartConstants.CREATE_INSTANCEPART_SUCCESS, resp})
  const failure = () => ({type: instancepartConstants.CREATE_INSTANCEPART_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await instancepartService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione istanza-parte effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione istanza-parte fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  //const request = () => ({type: instancepartConstants.READ_INSTANCEPARTS_REQUEST})
  const success = resp => ({type: instancepartConstants.READ_INSTANCEPARTS_SUCCESS, resp})
  const failure = () => ({type: instancepartConstants.READ_INSTANCEPARTS_FAILURE})
  return async dispatch => {
    //dispatch(request())
    try {
      const resp = await instancepartService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura instanze-parte fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
