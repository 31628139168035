import { materialConstants } from '../constant/material'


export default function material(state = {}, action) {
  switch (action.type) {

    case materialConstants.READ_MATERIALS_SUCCESS:
      return { ...state, totalCount: action.resp.totalCount }

    // case materialConstants.READ_MATERIALS_SUCCESS:
    //   return { ...state, readAll: action.resp, isReady: true }

    // case materialConstants.READ_MATERIALS_FAILURE:
    //   return { ...state, isReady: true }

    default:
      return state
  }
}