

export const geometryConstants = {
  UPDATE_GEOMETRY_REQUEST: 'UPDATE_GEOMETRY_REQUEST',
  UPDATE_GEOMETRY_SUCCESS: 'UPDATE_GEOMETRY_SUCCESS',
  UPDATE_GEOMETRY_FAILURE: 'UPDATE_GEOMETRY_FAILURE',
  DELETE_GEOMETRY_REQUEST: 'DELETE_GEOMETRY_REQUEST',
  DELETE_GEOMETRY_SUCCESS: 'DELETE_GEOMETRY_SUCCESS',
  DELETE_GEOMETRY_FAILURE: 'DELETE_GEOMETRY_FAILURE',
  CREATE_GEOMETRY_REQUEST: 'CREATE_GEOMETRY_REQUEST',
  CREATE_GEOMETRY_SUCCESS: 'CREATE_GEOMETRY_SUCCESS',
  CREATE_GEOMETRY_FAILURE: 'CREATE_GEOMETRY_FAILURE',
  READ_GEOMETRIES_REQUEST: 'READ_GEOMETRIES_REQUEST',
  READ_GEOMETRIES_SUCCESS: 'READ_GEOMETRIES_SUCCESS',
  READ_GEOMETRIES_FAILURE: 'READ_GEOMETRIES_FAILURE',
}
