import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, Link } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    itemIconSecondary: {
        marginLeft: 8,
    },
    itemSecondaryText: {
        fontSize: 13,
        lineHeight: 0.8
    },
}))


export default function DrawerMenu2SubItem(props) {
    const classes = useStyles()
    const location = useLocation()
    const { title, route, icon } = props

    // console.log('DrawerMenu2SubItem render')

    return (
        <ListItem button selected={location.pathname === route} component={Link} to={route} >
            <ListItemIcon className={classes.itemIconSecondary}>
                {icon}
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.itemSecondaryText }} primary={title} />
        </ListItem>
    )
}

