import axiosCommon from '../helper/axiosCommon'
import qs from 'qs'


async function updateOne(configuredProduct) {
  const req = axiosCommon.getAxiosParamsAuth(`/configured-products/${configuredProduct.id}`, 'put', configuredProduct)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/configured-products/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(configuredProduct) {
  const req = axiosCommon.getAxiosParamsAuth('/configured-products', 'post', configuredProduct)
  return await axiosCommon.axiosRequest(req)
}

async function readPaginated(query) {
  const req = axiosCommon.getAxiosParamsAuth('/configured-products?' + qs.stringify(query), 'get')
  return await axiosCommon.axiosRequest(req)
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
}


