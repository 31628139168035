// import axios from 'axios'
// import moment from 'moment'
import compareVersions from 'compare-versions'
import mainLayoutActions from '../action/mainLayout'
import { store } from './store'
import { readLocalStorageSettings, writeLocalStorageSettings } from './localStorage'
import axiosCommon from './axiosCommon'


const checkIntervalMinutes = 30


export const updatedVersionCheck = () => {
  // console.log('updatedVersionCheck')
  const settings = readLocalStorageSettings() || {}
  const currentVersion = process.env.REACT_APP_VERSION
  const cachedVersion = settings.app_version
  if (cachedVersion && compareVersions.validate(cachedVersion)) {
    if (compareVersions.validate(currentVersion) && compareVersions.compare(currentVersion, cachedVersion, '>')) {
      settings.app_version = currentVersion
      writeLocalStorageSettings(settings)
      // console.log(`Versione aggiornata! ${cachedVersion} -> ${currentVersion}`)
      store.dispatch(mainLayoutActions.infoSnackbar(`Aggiornamento versione effettuato ${cachedVersion} -> ${currentVersion}`))
    }
  }
  else {
    settings.app_version = currentVersion
    writeLocalStorageSettings(settings)
  }
}

// const logTimestamp = () => '[' + moment().format() + ']'

const versionCheckTask = async () => {
  try {
    const response = await axiosCommon.uiGetTimestamp('version.txt')
    // console.log(`${logTimestamp()} current: ${process.env.REACT_APP_VERSION} - version.txt: ${resp.data}`)
    const cachedVersion = process.env.REACT_APP_VERSION
    const currentVersion = response
    if (compareVersions.validate(currentVersion) && compareVersions.validate(cachedVersion) && compareVersions.compare(currentVersion, cachedVersion, '>')) {
      // console.log(`È disponibile un nuovo aggiornamento! ${cachedVersion} -> ${currentVersion}`)
      store.dispatch(mainLayoutActions.setIsUpdateAvailable({ isAvailable: true, version: currentVersion }))
      store.dispatch(mainLayoutActions.setNotificationBadge(1))
    }
  }
  catch (err) { }
}

export const startVersionCheckTask = () => {
  // console.log(`${logTimestamp()} VersionCheck Task started`)
  return setInterval(versionCheckTask, checkIntervalMinutes * 60e3)
}

