import React from 'react'
import PageLayout from '../PageLayout'
import ConfiguredProductsTable from './ConfiguredProductsTable'

// const useStyles = makeStyles(theme => ({
//     content: {
//        //
//     },
// }))


export default function AppConfiguredProductsPage() {
    // const classes = useStyles()

    // ('AppConfiguredProductsPage render')

    return (
        <PageLayout>
            <ConfiguredProductsTable />
        </PageLayout>
    )
}

