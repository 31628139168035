import React from 'react'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'


export default function CenteredSpinner() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '90vh' }}>
      <CircularProgress />
    </Box>
  )
}