import React from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import DynamicTable from '../../component/DynamicTable'
import { localization, cardTableOptions } from '../../helper/table'
import statisticActions from '../../action/statistic'
import CenteredSpinner from '../../component/CenteredSpinner'
import { useParams } from 'react-router-dom'



const getPatternmixTitle = (title, extra) => {
  let patternmixTitle = title
  try {
    const patternmixExtra = JSON.parse(extra)
    patternmixTitle = title + ` (${patternmixExtra.i18n.patternmixName.en})`
  }
  catch (err) { }
  return patternmixTitle
}




function Statistics({ dispatch, statistics, isReady }) {
  const params = useParams()
  
  React.useEffect(() => {
    dispatch(statisticActions.readAll({ appId: params.appId }))
  }, [dispatch, params])

  return !isReady ?
    <CenteredSpinner /> :
    (
      <div>

        <Box p={4} display="flex" justifyContent="center">
          <Card style={{ width: '80%' }} variant="outlined">
            <DynamicTable
              columns={[
                // { title: "App", field: "app_title" },
                { title: "Prodotto", field: "product_title" },
                { title: "Totale", field: "count", defaultSort: 'desc', type: 'numeric' },
                { title: "Totale %", field: "perc", type: 'numeric' },
              ]}
              data={statistics.product_histogram}
              title={'Prodotti in Composizioni'}
              options={cardTableOptions}
              localization={localization}
            />
          </Card>
        </Box>

        <Box p={4} display="flex" justifyContent="center">
          <Card style={{ width: '80%' }} variant="outlined">
            <DynamicTable
              columns={[
                // { title: "App", field: "app_title" },
                {
                  title: "Finitura", field: "patternmix_title",
                  render: rowData => getPatternmixTitle(rowData.patternmix_title, rowData.patternmix_extra)
                },
                { title: "Totale", field: "count", defaultSort: 'desc', type: 'numeric' },
              ]}
              data={statistics.patternmix_histogram}
              title={'Finiture in Composizioni'}
              options={cardTableOptions}
              localization={localization}
            />
          </Card>
        </Box>

        <Box p={4} display="flex" justifyContent="center">
          <Card style={{ width: '80%' }} variant="outlined">
            <DynamicTable
              columns={[
                // { title: "App", field: "app_title" },
                { title: "Istanza-parte", field: "instancepart_title" },
                {
                  title: "Finitura", field: "patternmix_title",
                  render: rowData => getPatternmixTitle(rowData.patternmix_title, rowData.patternmix_extra)
                },
                { title: "Totale", field: "count", defaultSort: 'desc', type: 'numeric' },
                { title: "Totale %", field: "perc", type: 'numeric' },
              ]}
              data={statistics.instancepart_patternmix_histogram}
              title={'Parti/Finiture in Composizioni'}
              options={cardTableOptions}
              localization={localization}
            />
          </Card>
        </Box>

      </div>

    )
}


const mapStateToProps = store => {
  const { statistic } = store
  return {
    isReady: statistic.isReady,
    statistics: statistic.readAll
  }
}

export default connect(mapStateToProps)(Statistics)