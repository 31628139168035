import { combineReducers } from 'redux'
import mainLayout from './mainLayout'
import user from './user'
import role from './role'
import permission from './permission'
import application from './application'
import product from './product'
import classpart from './classpart'
import instancepart from './instancepart'
import patternmix from './patternmix'
import pattern from './pattern'
import color from './color'
import material from './material'
import geometry from './geometry'
import map from './map'
import composition from './composition'
import configuredProduct from './configuredProduct'
import configuredPart from './configuredPart'
import statistic from './statistic'
import { authConstants } from '../constant/auth'
import { initialState } from '../helper/store'


const appReducer = combineReducers({
  mainLayout,
  user,
  role,
  permission,
  application,
  product,
  classpart,
  instancepart,
  patternmix,
  pattern,
  color,
  material,
  geometry,
  map,
  composition,
  configuredProduct,
  configuredPart,
  statistic
})

const rootReducer = (state, action) => {
  if (action.type === authConstants.LOGOUT_SUCCESS || action.type === authConstants.LOGOUT_FAILURE) {
    state = initialState
  }
  // console.log('rootReducer state', state)
  // console.log('rootReducer action', action)
  return appReducer(state, action)
}

export { rootReducer }
