

export const classpartConstants = {
  UPDATE_CLASSPART_REQUEST: 'UPDATE_CLASSPART_REQUEST',
  UPDATE_CLASSPART_SUCCESS: 'UPDATE_CLASSPART_SUCCESS',
  UPDATE_CLASSPART_FAILURE: 'UPDATE_CLASSPART_FAILURE',
  DELETE_CLASSPART_REQUEST: 'DELETE_CLASSPART_REQUEST',
  DELETE_CLASSPART_SUCCESS: 'DELETE_CLASSPART_SUCCESS',
  DELETE_CLASSPART_FAILURE: 'DELETE_CLASSPART_FAILURE',
  CREATE_CLASSPART_REQUEST: 'CREATE_CLASSPART_REQUEST',
  CREATE_CLASSPART_SUCCESS: 'CREATE_CLASSPART_SUCCESS',
  CREATE_CLASSPART_FAILURE: 'CREATE_CLASSPART_FAILURE',
  READ_CLASSPARTS_REQUEST: 'READ_CLASSPARTS_REQUEST',
  READ_CLASSPARTS_SUCCESS: 'READ_CLASSPARTS_SUCCESS',
  READ_CLASSPARTS_FAILURE: 'READ_CLASSPARTS_FAILURE',
}
