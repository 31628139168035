import { permissionConstants } from '../constant/permission'


export default function permission(state = {}, action) {
  switch (action.type) {

    case permissionConstants.READ_PERMISSIONS_LIST_SUCCESS:
      return { ...state, list: action.resp }

    case permissionConstants.READ_PERMISSIONS_SUCCESS:
      return { ...state, totalCount: action.resp.totalCount }

    // case permissionConstants.READ_PERMISSIONS_SUCCESS:
    //   return { ...state, readAll: action.resp, isReady: true }
    
    // case permissionConstants.READ_PERMISSIONS_FAILURE:
    //     return { ...state, isReady: true }

    default:
      return state
  }
}