

export const permissionConstants = {
  READ_PERMISSIONS_LIST_REQUEST: 'READ_PERMISSIONS_LIST_REQUEST',
  READ_PERMISSIONS_LIST_SUCCESS: 'READ_PERMISSIONS_LIST_SUCCESS',
  READ_PERMISSIONS_LIST_FAILURE: 'READ_PERMISSIONS_LIST_FAILURE',
  UPDATE_PERMISSION_REQUEST: 'UPDATE_PERMISSION_REQUEST',
  UPDATE_PERMISSION_SUCCESS: 'UPDATE_PERMISSION_SUCCESS',
  UPDATE_PERMISSION_FAILURE: 'UPDATE_PERMISSION_FAILURE',
  DELETE_PERMISSION_REQUEST: 'DELETE_PERMISSION_REQUEST',
  DELETE_PERMISSION_SUCCESS: 'DELETE_PERMISSION_SUCCESS',
  DELETE_PERMISSION_FAILURE: 'DELETE_PERMISSION_FAILURE',
  CREATE_PERMISSION_REQUEST: 'CREATE_PERMISSION_REQUEST',
  CREATE_PERMISSION_SUCCESS: 'CREATE_PERMISSION_SUCCESS',
  CREATE_PERMISSION_FAILURE: 'CREATE_PERMISSION_FAILURE',
  READ_PERMISSIONS_REQUEST: 'READ_PERMISSIONS_REQUEST',
  READ_PERMISSIONS_SUCCESS: 'READ_PERMISSIONS_SUCCESS',
  READ_PERMISSIONS_FAILURE: 'READ_PERMISSIONS_FAILURE',
}
