import axiosCommon from '../helper/axiosCommon'
import qs from 'qs'


async function updateOne(geometry) {
  const req = axiosCommon.getAxiosParamsAuth(`/geometries/${geometry.id}`, 'put', geometry)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/geometries/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(geometry) {
  const req = axiosCommon.getAxiosParamsAuth('/geometries', 'post', geometry)
  return await axiosCommon.axiosRequest(req)
}

async function readPaginated(query) {
  const req = axiosCommon.getAxiosParamsAuth('/geometries?' + qs.stringify(query), 'get')
  return await axiosCommon.axiosRequest(req)
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
}


