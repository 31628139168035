

export const colorConstants = {
  UPDATE_COLOR_REQUEST: 'UPDATE_COLOR_REQUEST',
  UPDATE_COLOR_SUCCESS: 'UPDATE_COLOR_SUCCESS',
  UPDATE_COLOR_FAILURE: 'UPDATE_COLOR_FAILURE',
  DELETE_COLOR_REQUEST: 'DELETE_COLOR_REQUEST',
  DELETE_COLOR_SUCCESS: 'DELETE_COLOR_SUCCESS',
  DELETE_COLOR_FAILURE: 'DELETE_COLOR_FAILURE',
  CREATE_COLOR_REQUEST: 'CREATE_COLOR_REQUEST',
  CREATE_COLOR_SUCCESS: 'CREATE_COLOR_SUCCESS',
  CREATE_COLOR_FAILURE: 'CREATE_COLOR_FAILURE',
  READ_COLORS_REQUEST: 'READ_COLORS_REQUEST',
  READ_COLORS_SUCCESS: 'READ_COLORS_SUCCESS',
  READ_COLORS_FAILURE: 'READ_COLORS_FAILURE',
}
