import { instancepartConstants } from '../constant/instancepart'


export default function instancepart(state = {}, action) {
  switch (action.type) {

    case instancepartConstants.READ_INSTANCEPARTS_SUCCESS:
      return { ...state, totalCount: action.resp.totalCount }

    // case instancepartConstants.READ_INSTANCEPARTS_SUCCESS:
    //   return { ...state, readAll: action.resp, isReady: true }

    // case instancepartConstants.READ_INSTANCEPARTS_FAILURE:
    //   return { ...state, isReady: true }

    default:
      return state
  }
}