

export const mapConstants = {
  UPDATE_MAP_REQUEST: 'UPDATE_MAP_REQUEST',
  UPDATE_MAP_SUCCESS: 'UPDATE_MAP_SUCCESS',
  UPDATE_MAP_FAILURE: 'UPDATE_MAP_FAILURE',
  DELETE_MAP_REQUEST: 'DELETE_MAP_REQUEST',
  DELETE_MAP_SUCCESS: 'DELETE_MAP_SUCCESS',
  DELETE_MAP_FAILURE: 'DELETE_MAP_FAILURE',
  CREATE_MAP_REQUEST: 'CREATE_MAP_REQUEST',
  CREATE_MAP_SUCCESS: 'CREATE_MAP_SUCCESS',
  CREATE_MAP_FAILURE: 'CREATE_MAP_FAILURE',
  READ_MAPS_REQUEST: 'READ_MAPS_REQUEST',
  READ_MAPS_SUCCESS: 'READ_MAPS_SUCCESS',
  READ_MAPS_FAILURE: 'READ_MAPS_FAILURE',
}
