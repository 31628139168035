import React from 'react'
// import { connect } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
// import { Box } from '@material-ui/core'
import PageLayout from '../PageLayout'
import PermissionsTable from './PermissionsTable'

// const useStyles = makeStyles(theme => ({
//   content: {
//     padding: theme.spacing(4)
//   },
// }))


export default function PermissionsPage(props) {
  // const classes = useStyles()

  // console.log('PermissionsPage render')

  return (
    <PageLayout mainDrawer={true} >
      <PermissionsTable />
    </PageLayout>

  )
}

// const mapStateToProps = store => {
//   const { application, mainLayout } = store
//   return {
//     apps: application.list,
//     permissionMap: mainLayout.readProfile.permissionMap
//   }
// }

// export default connect(mapStateToProps)(PermissionsPage)
