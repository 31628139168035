

export const configuredProductConstants = {
  UPDATE_CONFIGURED_PRODUCT_REQUEST: 'UPDATE_CONFIGURED_PRODUCT_REQUEST',
  UPDATE_CONFIGURED_PRODUCT_SUCCESS: 'UPDATE_CONFIGURED_PRODUCT_SUCCESS',
  UPDATE_CONFIGURED_PRODUCT_FAILURE: 'UPDATE_CONFIGURED_PRODUCT_FAILURE',
  DELETE_CONFIGURED_PRODUCT_REQUEST: 'DELETE_CONFIGURED_PRODUCT_REQUEST',
  DELETE_CONFIGURED_PRODUCT_SUCCESS: 'DELETE_CONFIGURED_PRODUCT_SUCCESS',
  DELETE_CONFIGURED_PRODUCT_FAILURE: 'DELETE_CONFIGURED_PRODUCT_FAILURE',
  CREATE_CONFIGURED_PRODUCT_REQUEST: 'CREATE_CONFIGURED_PRODUCT_REQUEST',
  CREATE_CONFIGURED_PRODUCT_SUCCESS: 'CREATE_CONFIGURED_PRODUCT_SUCCESS',
  CREATE_CONFIGURED_PRODUCT_FAILURE: 'CREATE_CONFIGURED_PRODUCT_FAILURE',
  READ_CONFIGURED_PRODUCTS_REQUEST: 'READ_CONFIGURED_PRODUCTS_REQUEST',
  READ_CONFIGURED_PRODUCTS_SUCCESS: 'READ_CONFIGURED_PRODUCTS_SUCCESS',
  READ_CONFIGURED_PRODUCTS_FAILURE: 'READ_CONFIGURED_PRODUCTS_FAILURE',
}
