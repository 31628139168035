import React from 'react'
// import PageLayout from './PageLayout'
import { useParams } from 'react-router-dom'
import PageLayout from './PageLayout'
import { connect } from 'react-redux';
import AppEntityCard from '../component/AppEntityCard';
import { Grid, Box, makeStyles } from '@material-ui/core';
// import { deleteLocalStorageData } from '../helper/localStorage';
// import UsersTable from './users/UsersTable'
import applicationService from '../service/application'
import AppInfoSidebar from '../component/layout/AppInfoSidebar';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    padding: theme.spacing(4)
  }
}))


function AppHomePage(props) {
  const classes = useStyles()
  const params = useParams()
  const { appMap } = props
  const app = appMap[params.appId] || {}
  const [data, setData] = React.useState({
    total: 150,
    enabled: 100,
    disabled: 20
  })

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await applicationService.readDashboard(params.appId)
      setData(resp)
    }
    fetchData()
  }, [params])

  // console.log('AppHomePage render', app)

  return (
    <PageLayout>
      <Box className={classes.content}>

        <Grid container spacing={4}>
          <Grid item xs={4}>
            <AppEntityCard title='Composizioni' data={data.compositions} />
          </Grid>
          <Grid item xs={4}>
            <AppEntityCard title='Prodotti' data={data.products} />
          </Grid>
          <Grid item xs={4}>
            <AppEntityCard title='Patternmix' data={data.patternmixes} />
          </Grid>
          <Grid item xs={4}>
            <AppEntityCard title='Istanze parte' data={data.instanceparts} />
          </Grid>
          <Grid item xs={4}>
            <AppEntityCard title='Materiali' data={data.materials} />
          </Grid>
          <Grid item xs={4}>
            <AppEntityCard title='Utenti' data={data.users} />
          </Grid>
        </Grid>

        <AppInfoSidebar app={app} />

      </Box>
      {/* app id: {params.appId}<br />
      app: {JSON.stringify(app, null, 2)} */}
    </PageLayout>
  )
}

const mapStateToProps = store => {
  const { application } = store
  return {
    appMap: application.appMap
  }
}

export default connect(mapStateToProps)(AppHomePage)
