import { patternmixConstants } from '../constant/patternmix'
import patternmixService from '../service/patternmix'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: patternmixConstants.UPDATE_PATTERNMIX_REQUEST})
  const success = resp => ({type: patternmixConstants.UPDATE_PATTERNMIX_SUCCESS, resp})
  const failure = () => ({type: patternmixConstants.UPDATE_PATTERNMIX_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await patternmixService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento patternmix effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento patternmix fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: patternmixConstants.DELETE_PATTERNMIX_REQUEST})
  const success = resp => ({type: patternmixConstants.DELETE_PATTERNMIX_SUCCESS, resp})
  const failure = () => ({type: patternmixConstants.DELETE_PATTERNMIX_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await patternmixService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione patternmix effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione patternmix fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: patternmixConstants.CREATE_PATTERNMIX_REQUEST})
  const success = resp => ({type: patternmixConstants.CREATE_PATTERNMIX_SUCCESS, resp})
  const failure = () => ({type: patternmixConstants.CREATE_PATTERNMIX_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await patternmixService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione patternmix effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione patternmix fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  //const request = () => ({type: patternmixConstants.READ_PATTERNMIXES_REQUEST})
  const success = resp => ({type: patternmixConstants.READ_PATTERNMIXES_SUCCESS, resp})
  const failure = () => ({type: patternmixConstants.READ_PATTERNMIXES_FAILURE})
  return async dispatch => {
    //dispatch(request())
    try {
      const resp = await patternmixService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura patternmixes fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
