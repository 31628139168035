import React from 'react'
import { connect } from 'react-redux'
import DynamicTable from '../../component/DynamicTable'
import mapActions from '../../action/map'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'
import { useParams } from 'react-router-dom'


function MapsTable({ dispatch, totalCount }) {
  const params = useParams();

  const fetchPaginatedData = query => dispatch(mapActions.readPaginated(getQueryParams({ ...query, appId: params.appId })))

  return (
    <DynamicTable
      columns={[
        { title: "Id", field: "id" },
        { title: "Uid", field: "map_id" },
        { title: "Titolo", field: "title" },
        { title: "Nome file", field: "filename" },
        { title: "Tipo", field: "type" },
        // { title: "App", field: "app_title" },
        { title: "Abilitato", field: "is_disabled", render: rowData => !rowData.is_disabled ? 'Si' : 'No' },
        { title: "Extra", field: "extra" },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
    />
  )
}

const mapStateToProps = store => {
  const { map } = store
  return {
    totalCount: map.totalCount
  }
}

export default connect(mapStateToProps)(MapsTable)