import axiosCommon from '../helper/axiosCommon'
import qs from 'qs'


async function updateOne(role) {
  const req = axiosCommon.getAxiosParamsAuth(`/roles/${role.id}`, 'put', role)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/roles/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(role) {
  const req = axiosCommon.getAxiosParamsAuth('/roles', 'post', role)
  return await axiosCommon.axiosRequest(req)
}

async function readPaginated(query) {
  const req = axiosCommon.getAxiosParamsAuth('/roles?' + qs.stringify(query), 'get')
  return await axiosCommon.axiosRequest(req)
}

async function readList() {
  const req = axiosCommon.getAxiosParamsAuth('/roles-list', 'get')
  return await axiosCommon.axiosRequest(req)
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
  readList
}


