import React from 'react'
import PageLayout from '../PageLayout'
import CompositionsTable from './CompositionsTable'

// const useStyles = makeStyles(theme => ({
//     content: {
//        //
//     },
// }))


export default function AppCompositionsPage() {
    // const classes = useStyles()

    // console.log('AppCompositionsPage render')

    return (
        <PageLayout>
            <CompositionsTable />
        </PageLayout>
    )
}

