

export const mainLayoutConstants = {
  SET_REDIRECT: 'SET_REDIRECT',
  SET_IS_UPDATE_AVAILABLE: 'SET_IS_UPDATE_AVAILABLE',
  SET_NOTIFICATION_BADGE: 'SET_NOTIFICATION_BADGE',
  ENQUEUE_SNACKBAR: 'ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',
  REMOVE_SNACKBAR: 'REMOVE_SNACKBAR',
  DRAWER_OPEN: 'DRAWER_OPEN',
  DRAWER_CLOSE: 'DRAWER_CLOSE'
}
