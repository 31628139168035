import React from 'react'
import PageLayout from '../PageLayout'
import ProductsTable from './ProductsTable'

// const useStyles = makeStyles(theme => ({
//     content: {
//        //
//     },
// }))


export default function AppProductsPage() {
    // const classes = useStyles()

    // console.log('AppProductsPage render')

    return (
        <PageLayout>
            <ProductsTable />
        </PageLayout>
    )
}

