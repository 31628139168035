import { permissionConstants } from '../constant/permission'
import permissionService from '../service/permission'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: permissionConstants.UPDATE_PERMISSION_REQUEST})
  const success = resp => ({type: permissionConstants.UPDATE_PERMISSION_SUCCESS, resp})
  const failure = () => ({type: permissionConstants.UPDATE_PERMISSION_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await permissionService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Aggiornamento permesso "${data.name}" effettuato.`))
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Aggiornamento permesso "${data.name}" fallito. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(data) {
  const request = () => ({type: permissionConstants.DELETE_PERMISSION_REQUEST})
  const success = resp => ({type: permissionConstants.DELETE_PERMISSION_SUCCESS, resp})
  const failure = () => ({type: permissionConstants.DELETE_PERMISSION_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await permissionService.deleteOne(data.id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Eliminazione permesso "${data.name}" effettuata.`))
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Eliminazione permesso "${data.name}" fallita. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: permissionConstants.CREATE_PERMISSION_REQUEST})
  const success = resp => ({type: permissionConstants.CREATE_PERMISSION_SUCCESS, resp})
  const failure = () => ({type: permissionConstants.CREATE_PERMISSION_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await permissionService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Creazione permesso "${data.name}" effettuata.`))
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Creazione permesso "${data.name}" fallita. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  // const request = () => ({type: permissionConstants.READ_PERMISSIONS_REQUEST})
  const success = resp => ({type: permissionConstants.READ_PERMISSIONS_SUCCESS, resp})
  const failure = () => ({type: permissionConstants.READ_PERMISSIONS_FAILURE})
  return async dispatch => {
    // dispatch(request())
    try {
      const resp = await permissionService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura permessi fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readList() {
  const request = () => ({type: permissionConstants.READ_PERMISSIONS_LIST_REQUEST})
  const success = resp => ({type: permissionConstants.READ_PERMISSIONS_LIST_SUCCESS, resp})
  const failure = () => ({type: permissionConstants.READ_PERMISSIONS_LIST_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await permissionService.readList()
      dispatch(success(resp))
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura permessi fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
  readList
}
