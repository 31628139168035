import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import userActions from '../../action/user'
import mainLayoutActions from '../../action/mainLayout'
import DynamicTable from '../../component/DynamicTable'
import TableChipSelect2 from '../../component/TableChipSelect2'
import TableEditTextField from '../../component/TableEditTextField'
import ChipArray from '../../component/ChipArray'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'



function UsersTable(props) {
  const { dispatch, totalCount, rolesList, appsList, appId } = props

  const [isErrorName, setIsErrorName] = React.useState(false)
  const [isErrorEmail, setIsErrorEmail] = React.useState(false)
  const [isErrorPassword, setIsErrorPassword] = React.useState(false)
  const [isErrorRole, setIsErrorRole] = React.useState(false)

  const validateUser = data => {
    // console.log('validateUser', data)
    const _isErrorName = _.isNil(data.name) || data.name === ''
    const _isErrorEmail = _.isNil(data.email) || data.email === ''
    const _isErrorPassword = _.isNil(data.passwd) || data.passwd === ''
    const _isErrorRole = _.isNil(data.roles) || data.roles.length === 0
    setIsErrorName(_isErrorName)
    setIsErrorEmail(_isErrorEmail)
    setIsErrorPassword(_isErrorPassword)
    setIsErrorRole(_isErrorRole)
    if (_isErrorName || _isErrorEmail || _isErrorPassword || _isErrorRole) {
      dispatch(mainLayoutActions.warningSnackbar('I campi Nome, Email, Password e Ruolo sono richiesti'))
      throw new Error()
    }
  }

  const prepareRequest = newData => {
    const { passwd, ...data } = newData
    // console.log('newData', newData)
    let req = {
      ...data,
      roles: newData.roles ? newData.roles.map(r => r.id) : [],
      permissions: [], // newData.permissions.map(p => p.id),
      applications: newData.applications ? newData.applications.map(a => a.id) : [],
    }
    if (passwd !== "●●●●") {
      req.password = passwd
    }
    return req
  }

  // const updateUsers = async () => {
  //   await dispatch(userActions.readProfile())
  // }

  const onRowAdd = async newData => {
    validateUser(newData)
    const req = prepareRequest(newData)
    await dispatch(userActions.createOne(req))
    // await updateUsers()
  }

  const onRowUpdate = async (newData, oldData) => {
    validateUser(newData)
    const req = prepareRequest(newData)
    await dispatch(userActions.updateOne(req))
    // await updateUsers()
  }

  const onRowDelete = async oldData => {
    await dispatch(userActions.deleteOne(oldData))
    // await updateUsers()
  }

  const fetchPaginatedData = query =>
    dispatch(userActions.readPaginated(getQueryParams(!_.isNil(appId) ? { ...query, appId } : query)))

  // console.log('UsersTable render')

  return (
    <DynamicTable
      columns={[
        // {
        //   title: "Id", field: "id", editable: 'onAdd',
        //   editComponent: props => <TableEditTextField props={props} />
        // },
        {
          title: "Nome", field: "name",
          editComponent: props => <TableEditTextField options={{ error: isErrorName }} props={props} />
        },
        {
          title: "Email", field: "email",
          editComponent: props => <TableEditTextField options={{ error: isErrorEmail }} props={props} />
        },
        {
          title: "Password", field: "passwd",
          editComponent: props => <TableEditTextField options={{ error: isErrorPassword }} props={props} />
        },
        {
          title: "Ruolo", field: "roles",
          editComponent: props =>
            <TableChipSelect2
              error={isErrorRole}
              selectedValues={props.rowData.roles} onChange={props.onChange}
              entities={rolesList} propName='name' multiple={false} />,
          render: rowData => <ChipArray array={rowData.roles} prop='name' />
        },
        // {
        //   title: "Permessi", field: "permissions",
        //   editComponent: props => <TableChipSelect2 selectedValues={props.rowData.permissions} onChange={props.onChange} entities={permissionsList} propName='name' />,
        //   render: rowData => <ChipArray array={rowData.permissions} prop='name' />
        // },
        {
          title: "Applicazioni", field: "applications",
          editComponent: props => <TableChipSelect2 selectedValues={props.rowData.applications} onChange={props.onChange} entities={appsList} propName='title' />,
          render: rowData => <ChipArray array={rowData.applications} prop='title' />
        },
        {
          title: "Proprietario", field: "owner", editable: 'never',
          render: rowData => rowData && rowData.owner ? rowData.owner.email : ''
        },
        { title: "Data creazione", field: "created_at", editable: 'never' },
        { title: "Data aggiornamento", field: "updated_at", editable: 'never', defaultSort: 'desc' },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
      editable={{ onRowAdd, onRowUpdate, onRowDelete }}
    />
  )
}


const mapStateToProps = store => {
  const { user, role, permission, application } = store
  return {
    totalCount: user.totalCount,
    rolesList: role.list,
    permissionsList: permission.list,
    appsList: application.list,
  }
}

export default connect(mapStateToProps)(UsersTable)
