import { classpartConstants } from '../constant/classpart'
import classpartService from '../service/classpart'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: classpartConstants.UPDATE_CLASSPART_REQUEST})
  const success = resp => ({type: classpartConstants.UPDATE_CLASSPART_SUCCESS, resp})
  const failure = () => ({type: classpartConstants.UPDATE_CLASSPART_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await classpartService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento classe-parte effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento classe-parte fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: classpartConstants.DELETE_CLASSPART_REQUEST})
  const success = resp => ({type: classpartConstants.DELETE_CLASSPART_SUCCESS, resp})
  const failure = () => ({type: classpartConstants.DELETE_CLASSPART_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await classpartService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione classe-parte effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione classe-parte fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: classpartConstants.CREATE_CLASSPART_REQUEST})
  const success = resp => ({type: classpartConstants.CREATE_CLASSPART_SUCCESS, resp})
  const failure = () => ({type: classpartConstants.CREATE_CLASSPART_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await classpartService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione classe-parte effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione classe-parte fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  //const request = () => ({type: classpartConstants.READ_CLASSPARTS_REQUEST})
  const success = resp => ({type: classpartConstants.READ_CLASSPARTS_SUCCESS, resp})
  const failure = () => ({type: classpartConstants.READ_CLASSPARTS_FAILURE})
  return async dispatch => {
    //dispatch(request())
    try {
      const resp = await classpartService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura classi-parte fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
