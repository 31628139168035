import { useEffect } from "react"
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import mainLayoutActions from '../../action/mainLayout'

let displayed = []


function SnackbarNotifier({ enqueueSnackbar, closeSnackbar, dispatch, notifications }) {

  // console.log('SnackbarNotifier notifications', notifications.length)

  useEffect(() => {
    // console.log('SnackbarNotifier useEffect', notifications)
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key)
        return
      }
      // Do nothing if snackbar is already displayed
      if (displayed.includes(key)) return
      // Display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, key) => {
          if (options.onClose) {
            options.onClose(event, reason, key)
          }
        },
        onExited: (event, key) => {
          dispatch(mainLayoutActions.removeSnackbar(key))
          displayed = displayed.filter(id => id !== key)
        }
      })
      // Keep track of snackbars that we've displayed
      displayed = [...displayed, key]
    })
    // console.log('SnackbarNotifier displayed', displayed.length)
  })

  return null
}


const mapStateToProps = store => {
  const { mainLayout } = store
  return {
    notifications: mainLayout.notifications,
  }
}

export default withSnackbar(connect(mapStateToProps)(SnackbarNotifier))