import { roleConstants } from '../constant/role'
import roleService from '../service/role'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: roleConstants.UPDATE_ROLE_REQUEST})
  const success = resp => ({type: roleConstants.UPDATE_ROLE_SUCCESS, resp})
  const failure = () => ({type: roleConstants.UPDATE_ROLE_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await roleService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Aggiornamento ruolo "${data.name}" effettuato.`))
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Aggiornamento ruolo "${data.name}" fallito. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(data) {
  const request = () => ({type: roleConstants.DELETE_ROLE_REQUEST})
  const success = resp => ({type: roleConstants.DELETE_ROLE_SUCCESS, resp})
  const failure = () => ({type: roleConstants.DELETE_ROLE_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await roleService.deleteOne(data.id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Eliminazione ruolo "${data.name}" effettuata.`))
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Eliminazione ruolo "${data.name}" fallita. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: roleConstants.CREATE_ROLE_REQUEST})
  const success = resp => ({type: roleConstants.CREATE_ROLE_SUCCESS, resp})
  const failure = () => ({type: roleConstants.CREATE_ROLE_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await roleService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Creazione ruolo "${data.name}" effettuata.`))
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Creazione ruolo "${data.name}" fallita. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  //const request = () => ({type: roleConstants.READ_ROLES_REQUEST})
  const success = resp => ({type: roleConstants.READ_ROLES_SUCCESS, resp})
  const failure = () => ({type: roleConstants.READ_ROLES_FAILURE})
  return async dispatch => {
    //dispatch(request())
    try {
      const resp = await roleService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura ruoli fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readList() {
  const request = () => ({type: roleConstants.READ_ROLES_LIST_REQUEST})
  const success = resp => ({type: roleConstants.READ_ROLES_LIST_SUCCESS, resp})
  const failure = () => ({type: roleConstants.READ_ROLES_LIST_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await roleService.readList()
      dispatch(success(resp))
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura ruoli fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
  readList
}
