import { productConstants } from '../constant/product'


export default function product(state = {}, action) {
  switch (action.type) {

    case productConstants.READ_PRODUCTS_SUCCESS:
      return { ...state, totalCount: action.resp.totalCount }

    // case productConstants.READ_PRODUCTS_SUCCESS:
    //   return { ...state, readAll: action.resp, isReady: true }

    // case productConstants.READ_PRODUCTS_FAILURE:
    //   return { ...state, isReady: true }

    default:
      return state
  }
}