import { statisticConstants } from '../constant/statistic'


export default function statistic(state = {}, action) {
  switch (action.type) {

    case statisticConstants.READ_STATISTICS_SUCCESS:
      return { ...state, readAll: action.resp, isReady: true }

    case statisticConstants.READ_STATISTICS_FAILURE:
      return { ...state, isReady: true }

    default:
      return state
  }
}