

export const roleConstants = {
  READ_ROLES_LIST_REQUEST: 'READ_ROLES_LIST_REQUEST',
  READ_ROLES_LIST_SUCCESS: 'READ_ROLES_LIST_SUCCESS',
  READ_ROLES_LIST_FAILURE: 'READ_ROLES_LIST_FAILURE',
  UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILURE: 'UPDATE_ROLE_FAILURE',
  DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILURE: 'DELETE_ROLE_FAILURE',
  CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILURE: 'CREATE_ROLE_FAILURE',
  READ_ROLES_REQUEST: 'READ_ROLES_REQUEST',
  READ_ROLES_SUCCESS: 'READ_ROLES_SUCCESS',
  READ_ROLES_FAILURE: 'READ_ROLES_FAILURE',
}
