import axiosCommon from '../helper/axiosCommon'


async function updateOne(statistic) {
  const req = axiosCommon.getAxiosParamsAuth(`/statistics/${statistic.id}`, 'put', statistic)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/statistics/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(statistic) {
  const req = axiosCommon.getAxiosParamsAuth('/statistics', 'post', statistic)
  return await axiosCommon.axiosRequest(req)
}

async function readAll(query) {
  const req = axiosCommon.getAxiosParamsAuth(`/statistics?appId=${query.appId}`, 'get')
  return await axiosCommon.axiosRequest(req)
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readAll,
}


