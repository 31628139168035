

const localStorageKey = `mio_userdata_${process.env.REACT_APP_HOST_ENV}`
const localStorageSettingsKey = `mio_usersettings_${process.env.REACT_APP_HOST_ENV}`

export function readLocalStorageData() {
  const serializedUserData = localStorage.getItem(localStorageKey);
  let userData = null
  try { userData = JSON.parse(serializedUserData) }
  catch {}
  return userData
}

export function writeLocalStorageData(userData) {
  localStorage.setItem(localStorageKey, JSON.stringify(userData));
}

export function deleteLocalStorageData() {
  localStorage.removeItem(localStorageKey)
}



export function readLocalStorageSettings() {
  const serializedUserSettings = localStorage.getItem(localStorageSettingsKey);
  let userSettings = null
  try { userSettings = JSON.parse(serializedUserSettings) }
  catch {}
  return userSettings
}

export function writeLocalStorageSettings(userSettings) {
  localStorage.setItem(localStorageSettingsKey, JSON.stringify(userSettings));
}

export function deleteLocalStorageSettings() {
  localStorage.removeItem(localStorageSettingsKey)
}