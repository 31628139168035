import React from 'react'
import { connect } from 'react-redux'
import DynamicTable from '../../component/DynamicTable'
import compositionActions from '../../action/composition'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'
import { useParams } from 'react-router-dom'


function CompositionsTable({ dispatch, totalCount }) {
  const params = useParams()

  const fetchPaginatedData = query => dispatch(compositionActions.readPaginated(getQueryParams({ ...query, appId: params.appId })))

  return (
    <DynamicTable
      columns={[
        { title: "Id", field: "id" },
        { title: "Uid", field: "composition_id" },
        { title: "Titolo", field: "title" },
        { title: "Persistente", field: "is_persistent", render: rowData => rowData.is_persistent ? 'Si' : 'No' },
        // { title: "App", field: "app_title" },
        { title: "Abilitato", field: "is_disabled", render: rowData => !rowData.is_disabled ? 'Si' : 'No' },
        { title: "Extra", field: "extra" },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
    />
  )
}

const mapStateToProps = store => {
  const { composition } = store
  return {
    totalCount: composition.totalCount
  }
}


export default connect(mapStateToProps)(CompositionsTable)