import { applicationConstants } from '../constant/application'
import _ from 'lodash'


export default function application(state = {}, action) {
  switch (action.type) {

    case applicationConstants.READ_APPLICATIONS_LIST_SUCCESS:
      return { ...state, list: action.resp, appMap: _.keyBy(action.resp, 'route_id') }

    case applicationConstants.READ_APPLICATIONS_SUCCESS:
      return { ...state, totalCount: action.resp.totalCount }

    // case applicationConstants.READ_APPLICATIONS_SUCCESS:
    //   return { ...state, readAll: action.resp, isReady: true }

    // case applicationConstants.READ_APPLICATIONS_FAILURE:
    //   return { ...state, isReady: true }

    default:
      return state
  }
}
