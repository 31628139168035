import React from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useLocation } from 'react-router-dom';
import { routes, routesBasePath } from '../../helper/routes';


export default function AutoBreadcrumbs() {
  const location = useLocation();
  const history = useHistory();

  const getBreadcrumbs = () => {
    let breadcrumbs = [
      {
        title: 'Applicazioni',
        route: routes.home,
      },
    ];
    const splittedLocation = location.pathname
      .replace(routesBasePath, '')
      .replace(/^\/|\/$/g, '')
      .split('/');
    splittedLocation.forEach((piece, i) => {
      if (piece) {
        let route = splittedLocation.slice(0, i + 1).join('/');
        breadcrumbs.push({
          title: piece.charAt(0).toUpperCase() + piece.substring(1),
          route: `${routesBasePath}${route}`,
        });
      }
    });
    return breadcrumbs;
  };

  const handleOnClick = route => () => {
    history.push(route);
  };

  const breadcrumbs = getBreadcrumbs()

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => {
        if (breadcrumbs.length === 1 || index === breadcrumbs.length - 1) {
          return (
            <Link
              style={{ cursor: 'pointer' }}
              color="textPrimary"
              onClick={handleOnClick(breadcrumb.route)}
              key={index}
            >
              {breadcrumb.title}
            </Link>
          );
        }
        return (
          <Link
            style={{ cursor: 'pointer' }}
            color="inherit"
            onClick={handleOnClick(breadcrumb.route)}
            aria-current="page"
            key={index}
          >
            {breadcrumb.title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
