import { geometryConstants } from '../constant/geometry'


export default function geometry(state = {}, action) {
  switch (action.type) {

    case geometryConstants.READ_GEOMETRIES_SUCCESS:
      return { ...state, totalCount: action.resp.totalCount }

    // case geometryConstants.READ_GEOMETRIES_SUCCESS:
    //   return { ...state, readAll: action.resp, isReady: true }

    // case geometryConstants.READ_GEOMETRIES_FAILURE:
    //   return { ...state, isReady: true }

    default:
      return state
  }
}