

export const instancepartConstants = {
  UPDATE_INSTANCEPART_REQUEST: 'UPDATE_INSTANCEPART_REQUEST',
  UPDATE_INSTANCEPART_SUCCESS: 'UPDATE_INSTANCEPART_SUCCESS',
  UPDATE_INSTANCEPART_FAILURE: 'UPDATE_INSTANCEPART_FAILURE',
  DELETE_INSTANCEPART_REQUEST: 'DELETE_INSTANCEPART_REQUEST',
  DELETE_INSTANCEPART_SUCCESS: 'DELETE_INSTANCEPART_SUCCESS',
  DELETE_INSTANCEPART_FAILURE: 'DELETE_INSTANCEPART_FAILURE',
  CREATE_INSTANCEPART_REQUEST: 'CREATE_INSTANCEPART_REQUEST',
  CREATE_INSTANCEPART_SUCCESS: 'CREATE_INSTANCEPART_SUCCESS',
  CREATE_INSTANCEPART_FAILURE: 'CREATE_INSTANCEPART_FAILURE',
  READ_INSTANCEPARTS_REQUEST: 'READ_INSTANCEPARTS_REQUEST',
  READ_INSTANCEPARTS_SUCCESS: 'READ_INSTANCEPARTS_SUCCESS',
  READ_INSTANCEPARTS_FAILURE: 'READ_INSTANCEPARTS_FAILURE',
}
