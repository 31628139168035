import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
// import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import ChipArray from '../component/ChipArray'


const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 100,
  }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

function getStyles(id, ids, theme) {
  return {
    fontSize: 14,
    fontWeight:
      ids.includes(id) ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular
  };
}


const filterEntities = (entities, ids) => entities.filter(e => ids.includes(e.id))


export default function TableChipSelect2(props) {
  const classes = useStyles()
  const theme = useTheme()
  const { selectedValues, onChange, entities, propName, multiple = true, error = false } = props

  // console.log('selectedValues', selectedValues)
  // console.log('entities', entities)

  let selectedIds = selectedValues ? selectedValues.map(v => v.id) : []

  // useEffect(() => {
  //   if (!selectedValues) { onChange([]) }
  // })

  const handleChange = event => {
    const ids = event.target.value
    // console.log('event.target.value', ids)
    const filtered = filterEntities(entities, multiple ? ids : [ids])
    onChange(filtered)
  }

  const handleRenderValue = selected => {
    const filtered = filterEntities(entities, selectedIds)
    return selected.length === 0 ?
      '' : <ChipArray array={filtered} prop={propName} />
  }

  // console.log('-------------- selectedIds', selectedIds)

  if (!multiple)
    selectedIds = selectedIds.length === 1 ? selectedIds : ''

  return (
    <FormControl className={classes.formControl} fullWidth >
      <Select
        // labelId="multiple-name-label"
        // id="multiple-name"
        error={error}
        multiple={multiple}
        value={selectedIds}
        onChange={handleChange}
        input={<Input /*id="select-multiple-chip-"*/ />}
        renderValue={handleRenderValue}
        MenuProps={MenuProps}
      >
        {
          entities.map((value, i) => (
            <MenuItem key={i} value={value.id} style={getStyles(value.id, selectedIds, theme)} >
              {value[propName]}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}
