

export const applicationConstants = {
  READ_APPLICATIONS_LIST_REQUEST: 'READ_APPLICATIONS_LIST_REQUEST',
  READ_APPLICATIONS_LIST_SUCCESS: 'READ_APPLICATIONS_LIST_SUCCESS',
  READ_APPLICATIONS_LIST_FAILURE: 'READ_APPLICATIONS_LIST_FAILURE',
  UPDATE_APPLICATION_REQUEST: 'UPDATE_APPLICATION_REQUEST',
  UPDATE_APPLICATION_SUCCESS: 'UPDATE_APPLICATION_SUCCESS',
  UPDATE_APPLICATION_FAILURE: 'UPDATE_APPLICATION_FAILURE',
  DELETE_APPLICATION_REQUEST: 'DELETE_APPLICATION_REQUEST',
  DELETE_APPLICATION_SUCCESS: 'DELETE_APPLICATION_SUCCESS',
  DELETE_APPLICATION_FAILURE: 'DELETE_APPLICATION_FAILURE',
  CREATE_APPLICATION_REQUEST: 'CREATE_APPLICATION_REQUEST',
  CREATE_APPLICATION_SUCCESS: 'CREATE_APPLICATION_SUCCESS',
  CREATE_APPLICATION_FAILURE: 'CREATE_APPLICATION_FAILURE',
  READ_APPLICATIONS_REQUEST: 'READ_APPLICATIONS_REQUEST',
  READ_APPLICATIONS_SUCCESS: 'READ_APPLICATIONS_SUCCESS',
  READ_APPLICATIONS_FAILURE: 'READ_APPLICATIONS_FAILURE',
}
