import { userConstants } from '../constant/user'
import userService from '../service/user'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'


function updateOne(data) {
  const request = () => ({ type: userConstants.UPDATE_USER_REQUEST })
  const success = resp => ({ type: userConstants.UPDATE_USER_SUCCESS, resp })
  const failure = () => ({ type: userConstants.UPDATE_USER_FAILURE })
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await userService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Aggiornamento utente "${data.email}" effettuato.`))
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Aggiornamento utente "${data.email}" fallito. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }
}

function deleteOne(data) {
  const request = () => ({ type: userConstants.DELETE_USER_REQUEST })
  const success = resp => ({ type: userConstants.DELETE_USER_SUCCESS, resp })
  const failure = () => ({ type: userConstants.DELETE_USER_FAILURE })
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await userService.deleteOne(data.id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Eliminazione utente "${data.email}" effettuata.`))
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Eliminazione utente "${data.email}" fallita. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }
}

function createOne(data) {
  const request = () => ({ type: userConstants.CREATE_USER_REQUEST })
  const success = resp => ({ type: userConstants.CREATE_USER_SUCCESS, resp })
  const failure = () => ({ type: userConstants.CREATE_USER_FAILURE })
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await userService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Creazione utente "${data.email}" effettuato.`))
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Creazione utente "${data.email}" fallita. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }
}

function readPaginated(query) {
  // const request = () => ({ type: userConstants.READ_USERS_REQUEST })
  const success = resp => ({ type: userConstants.READ_USERS_SUCCESS, resp })
  const failure = () => ({ type: userConstants.READ_USERS_FAILURE })
  return async dispatch => {
    // dispatch(request())
    try {
      const resp = await userService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura utenti fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }
}

function readProfile() {
  const request = () => ({ type: userConstants.READ_PROFILE_REQUEST })
  const success = resp => ({ type: userConstants.READ_PROFILE_SUCCESS, resp })
  const failure = () => ({ type: userConstants.READ_PROFILE_FAILURE })
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await userService.readProfile()
      dispatch(success(resp))
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Lettura profilo utente fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
  readProfile
}
