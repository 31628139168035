import { configuredPartConstants } from '../constant/configuredPart'
import configuredPartService from '../service/configuredPart'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: configuredPartConstants.UPDATE_CONFIGURED_PART_REQUEST})
  const success = resp => ({type: configuredPartConstants.UPDATE_CONFIGURED_PART_SUCCESS, resp})
  const failure = () => ({type: configuredPartConstants.UPDATE_CONFIGURED_PART_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await configuredPartService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento parte-configurata effettuato.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento parte-configurata fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: configuredPartConstants.DELETE_CONFIGURED_PART_REQUEST})
  const success = resp => ({type: configuredPartConstants.DELETE_CONFIGURED_PART_SUCCESS, resp})
  const failure = () => ({type: configuredPartConstants.DELETE_CONFIGURED_PART_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await configuredPartService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione parte-configurata effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione parte-configurata fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: configuredPartConstants.CREATE_CONFIGURED_PART_REQUEST})
  const success = resp => ({type: configuredPartConstants.CREATE_CONFIGURED_PART_SUCCESS, resp})
  const failure = () => ({type: configuredPartConstants.CREATE_CONFIGURED_PART_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await configuredPartService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione parte-configurata effettuata.'))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione parte-configurata fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readPaginated(query) {
  // const request = () => ({type: configuredPartConstants.READ_CONFIGURED_PARTS_REQUEST})
  const success = resp => ({type: configuredPartConstants.READ_CONFIGURED_PARTS_SUCCESS, resp})
  const failure = () => ({type: configuredPartConstants.READ_CONFIGURED_PARTS_FAILURE})
  return async dispatch => {
    // dispatch(request())
    try {
      const resp = await configuredPartService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura parti-configurate fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated
}
