

export const userConstants = {
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  READ_USERS_REQUEST: 'READ_USERS_REQUEST',
  READ_USERS_SUCCESS: 'READ_USERS_SUCCESS',
  READ_USERS_FAILURE: 'READ_USERS_FAILURE',
  READ_PROFILE_REQUEST: 'READ_PROFILE_REQUEST',
  READ_PROFILE_SUCCESS: 'READ_PROFILE_SUCCESS',
  READ_PROFILE_FAILURE: 'READ_PROFILE_FAILURE'
}
