import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import TableEditTextField from '../../component/TableEditTextField'
import DynamicTable from '../../component/DynamicTable'
import TableChipSelect2 from '../../component/TableChipSelect2'
import ChipArray from '../../component/ChipArray'
import roleActions from '../../action/role'
import userActions from '../../action/user'
import mainLayoutActions from '../../action/mainLayout'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'




function RolesTable({ dispatch, totalCount, permissionsList }) {

  // console.log('RolesTable')

  const [isErrorSlug, setIsErrorSlug] = React.useState(false)
  const [isErrorName, setIsErrorName] = React.useState(false)

  const validateRole = data => {
    const _isErrorSlug = _.isNil(data.slug) || data.slug === ''
    const _isErrorName = _.isNil(data.name) || data.name === ''
    setIsErrorSlug(_isErrorSlug)
    setIsErrorName(_isErrorName)
    if (_isErrorSlug || _isErrorName) {
      dispatch(mainLayoutActions.warningSnackbar('I campi Slug e Nome sono richiesti'))
      throw new Error()
    }
  }

  const prepareRequest = newData => ({
    ...newData,
    permissions: newData.permissions.map(p => p.id)
  })

  const updateRoles = async () => {
    await dispatch(roleActions.readList())
    await dispatch(userActions.readProfile())
  }

  const onRowAdd = async newData => {
    validateRole(newData)
    const req = prepareRequest(newData)
    await dispatch(roleActions.createOne(req))
    await updateRoles()
  }

  const onRowUpdate = async (newData, oldData) => {
    validateRole(newData)
    const req = prepareRequest(newData)
    await dispatch(roleActions.updateOne(req))
    await updateRoles()
  }

  const onRowDelete = async oldData => {
    await dispatch(roleActions.deleteOne(oldData))
    await updateRoles()
  }

  const fetchPaginatedData = query => dispatch(roleActions.readPaginated(getQueryParams(query)))

  return (
    <DynamicTable
      columns={[
        {
          title: "Id", field: "id", editable: 'onAdd',
          editComponent: props => <TableEditTextField props={props} />
        },
        {
          title: "Slug", field: "slug",
          editComponent: props => <TableEditTextField options={{ error: isErrorSlug }} props={props} />
        },
        {
          title: "Nome", field: "name",
          editComponent: props => <TableEditTextField options={{ error: isErrorName }} props={props} />
        },
        {
          title: "Permessi", field: "permissions",
          editComponent: props => <TableChipSelect2 selectedValues={props.rowData.permissions} onChange={props.onChange} entities={permissionsList} propName='name' />,
          render: rowData => <ChipArray array={rowData.permissions} prop='name' />
        },
        { title: "Data creazione", field: "created_at", editable: 'never' },
        { title: "Data aggiornamento", field: "updated_at", editable: 'never', defaultSort: 'desc' },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
      editable={{ onRowAdd, onRowUpdate, onRowDelete }}
    />
  )
}


const mapStateToProps = store => {
  const { role, permission } = store
  return {
    totalCount: role.totalCount,
    permissionsList: permission.list,
  }
}

export default connect(mapStateToProps)(RolesTable)
