import React from 'react'
import { connect } from 'react-redux'
import DynamicTable from '../../component/DynamicTable'
import geometryActions from '../../action/geometry'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'
import { useParams } from 'react-router-dom'


function GeometriesTable({ dispatch, totalCount }) {
  const params = useParams();

  const fetchPaginatedData = query => dispatch(geometryActions.readPaginated(getQueryParams({ ...query, appId: params.appId })))

  return (
    <DynamicTable
      columns={[
        { title: "Id", field: "id" },
        { title: "Uid", field: "geometry_id" },
        { title: "Titolo", field: "title" },
        { title: "Nome file", field: "filename" },
        // { title: "App", field: "app_title" },
        { title: "Abilitato", field: "is_disabled", render: rowData => !rowData.is_disabled ? 'Si' : 'No' },
        { title: "Extra", field: "extra" },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
    />
  )
}

const mapStateToProps = store => {
  const { geometry } = store
  return {
    totalCount: geometry.totalCount
  }
}


export default connect(mapStateToProps)(GeometriesTable)